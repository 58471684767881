import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { brandState, appState, eventsState, userState } from '../Recoil/atoms';
import apiHandler from '../Handlers/apiHandler';
import { Box } from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';

import EventFilters from './EventsPage/EventFilters';
import EventList from './EventsPage/EventList';
import LoadMoreButton from './EventsPage/LoadMoreButton';

const Events = () => {
    const { logout } = useContext(AuthContext);
    const [brandData] = useRecoilState(brandState);
    const [isLoading, setLoading] = useRecoilState(appState);
    const [currentEvents, setCurrentEvents] = useRecoilState(eventsState);
    const [currentUser] = useRecoilState(userState);

    const pageRef = useRef(0);
    const [updating, setUpdating] = useState(false);
    const [allEventsLoaded, setAllEventsLoaded] = useState(false);
    const [whenEvents, setWhenEvents] = useState('upcoming');

    const navigate = useNavigate();

    const fetchFutureEvents = async () => {
        setUpdating(true);
        setLoading(true);

        try {
            const events = await apiHandler.getPublicEvents(brandData?.type, brandData?.result.id, whenEvents, pageRef.current, logout);

            if (events?.length) {
                await setCurrentEvents(prev => [...prev, ...events]);
                pageRef.current += 1;
                if(events?.length < 5) {
                    setAllEventsLoaded(true);
                }
            } else {
                setAllEventsLoaded(true);
            }

        } catch (error) {
            console.error(error);
        } finally {
            setUpdating(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        setAllEventsLoaded(false);
        setCurrentEvents([]);
        pageRef.current = 0;
        fetchFutureEvents();
    }, [brandData, whenEvents]);

    return (
        <Box padding="10px" w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" alignItems="center">
            {currentUser && currentUser.role !== 'client' && (
                <EventFilters whenEvents={whenEvents} setWhenEvents={setWhenEvents} />
            )}
            <EventList events={currentEvents} navigate={navigate} />
            {
                !allEventsLoaded && currentEvents?.length > 0 &&  (<LoadMoreButton isLoading={updating} allEventsLoaded={allEventsLoaded} onLoadMore={fetchFutureEvents} />)
            }
        </Box>
    );
};

export default Events;