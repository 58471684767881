const getReady = (user) => {
    const originalString = user?.user_can;

    let unescapedString;

    if (typeof originalString === 'string') {
        unescapedString = originalString.startsWith('"') && originalString.endsWith('"')
            ? originalString.slice(1, -1).replace(/\\"/g, '"')  // Elimina las comillas exteriores y desescapa las comillas internas
            : originalString;
    } else {
        unescapedString = originalString;
    }

    const readyUser = {
        ...user,
        user_can: unescapedString && typeof unescapedString === 'string' ? JSON.parse(unescapedString) : unescapedString
    }
    return readyUser;
}

const userHandler = {
    getReady
}

export default userHandler;