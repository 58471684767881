import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Image, Box, IconButton, useDisclosure, useTheme, Button, Skeleton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Keyboard, Virtual } from 'swiper/modules'; // Importar Virtual

import { AuthContext } from '../context/AuthContext';

const GalleryComponent = ({ images, album }) => {

  const { logout } = useContext(AuthContext);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [loadingThumbnails, setLoadingThumbnails] = useState(true);

  const theme = useTheme(); // Usar el tema de Chakra UI
  const arrowColor = theme.colors.whiteScheme?.[700]; // Color del tema Chakra UI

  const handleClick = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleDownload = () => {
    const currentImage = images[selectedIndex].url;
    const fileExtension = currentImage.split('.').pop(); // Obtener la extensión del archivo
    const link = document.createElement('a');
    link.href = currentImage;
    link.setAttribute('download', `${album.name}-${selectedIndex}.${fileExtension}`);
    link.setAttribute('target', '_blank'); // Abrir en nueva ventana para forzar la descarga
    document.body.appendChild(link); // Necesario para Firefox
    link.click();
    document.body.removeChild(link); // Limpiar el enlace
  };

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight); // Actualizar el estado cuando la ventana cambia de tamaño
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            observer.unobserve(img); // Deja de observar la imagen una vez cargada
          }
        });
      },
      {
        rootMargin: '50px', // Pre-carga imágenes justo antes de que sean visibles
        threshold: 0.1, // Ajusta la sensibilidad del lazy load
      }
    );
  
    const images = document.querySelectorAll('img.lazy');
    images.forEach((img) => {
      observer.observe(img);
    });
  
    return () => {
      images.forEach((img) => {
        observer.unobserve(img);
      });
    };
  }, []);

  return (
    <Box padding="10px" w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      {/* Grid de imágenes */}
      <SimpleGrid columns={[2, 2, 3]} spacing={4}>
        {images?.map((src, index) => (
          <Box
            key={index}
            w="100%" 
            pb="100%" 
            position="relative" 
            cursor="pointer"
            onClick={() => handleClick(index)}
          >
            {/* Placeholder de carga */}
            {loadingThumbnails && (
              <Skeleton height="100%" width="100%" borderRadius="10px" />
            )}
            <Image
              data-src={src.url}
              alt={`Photo ${index}`}
              className="lazy"
              loading="lazy" // Lazy load en las miniaturas
              objectFit="cover" 
              w="100%"
              h="100%"
              position="absolute"
              top={0}
              left={0}
              borderRadius="10px"
              onLoad={() => setLoadingThumbnails(false)} // Ocultar Skeleton cuando se cargue la imagen
            />
          </Box>
        ))}
      </SimpleGrid>

      {/* Modal con Swiper */}
      {isOpen && (
        <Box 
          position="fixed" 
          top={0} 
          left={0} 
          width="100vw" 
          height={viewportHeight} 
          bg="blackAlpha.700" 
          zIndex={9999} 
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={onClose} // Cierra el modal cuando se hace clic fuera del Swiper
        >
          {/* Botón de cierre */}
          <IconButton
            icon={<CloseIcon />}
            position="absolute"
            top="10px"
            right="10px"
            colorScheme="whiteScheme"
            borderRadius="full"
            aria-label="Cerrar"
            size="sm"
            zIndex={20}
            onClick={onClose}
            variant="outline"
          />

          <Box 
            width={{ base: '90%', md: '70%', lg: '50%' }} 
            height="80vh"  // Limitar la altura del modal
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={(e) => e.stopPropagation()} // Previene que se cierre al hacer clic dentro del Swiper
          >
            <Swiper
              initialSlide={selectedIndex}
              spaceBetween={10}
              slidesPerView={1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              keyboard={{ enabled: true }} // Habilitar navegación por teclado
              virtual // Habilitar Virtual Slides
              lazy={{ loadPrevNext: true, loadOnTransitionStart: false }} // Configuración de Lazy Loading
              modules={[Navigation, Keyboard, Virtual]} // No necesitas Lazy aquí
              style={{ height: '100%' }}
            >
              {images?.map((src, index) => (
                <SwiperSlide 
                  key={index} 
                  virtualIndex={index} // Usar la propiedad virtualIndex
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                >
                  <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    height="100%" 
                    width="100%"
                  >
                    <Image 
                      src={src.url} 
                      alt={`Slide ${index}`} 
                      maxHeight="100%" 
                      maxWidth="100%" 
                      objectFit="contain" // Asegurar que la imagen se vea completa sin recortar
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          {/* Botón flotante para descargar */}
          <Button
            position="absolute"
            bottom="40px"
            left="50%"
            variant="outline"
            transform="translateX(-50%)"
            colorScheme="whiteScheme"
            onClick={handleDownload}
            zIndex={10}
            borderRadius="full"
          >
            Descargar
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default GalleryComponent;