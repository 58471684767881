import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';

import { AuthProvider } from './context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  colors: {
    whiteScheme: {
      50: '#FEFEFD',
      100: '#FDFDFB',
      200: '#FCFCF9',
      300: '#FAF9F3',
      400: '#F7F6ED',
      500: '#F0EFE8',  // color base
      600: '#D9D8D1',
      700: '#BFBEB7',
      800: '#A5A59E',
      900: '#8B8B85',
    },
    blackScheme: {
      50: '#D4D4D3',
      100: '#A9A9A7',
      200: '#7E7E7C',
      300: '#535351',
      400: '#282826',
      500: '#1D1D1B',  // color base
      600: '#181817',
      700: '#131312',
      800: '#0E0E0E',
      900: '#090909',
    },
  },
  components: {
    Button: {
      variants: {
        solid: (props) => ({
          bg: props.colorScheme === 'whiteScheme' ? 'whiteScheme.500' : 'blackScheme.500',
          color: props.colorScheme === 'whiteScheme' ? 'blackScheme.900' : 'whiteScheme.50', // Negro para whiteScheme, blanco para blackScheme
          _hover: {
            bg: props.colorScheme === 'whiteScheme' ? 'whiteScheme.600' : 'blackScheme.600',
          },
          _active: {
            bg: props.colorScheme === 'whiteScheme' ? 'whiteScheme.700' : 'blackScheme.700',
          },
        }),
        outline: (props) => ({
          borderColor: props.colorScheme === 'whiteScheme' ? 'whiteScheme.500' : 'blackScheme.500',
          color: props.colorScheme === 'whiteScheme' ? 'whiteScheme.500' : 'blackScheme.500',
          _hover: {
            bg: props.colorScheme === 'whiteScheme' ? 'whiteScheme.100' : 'blackScheme.600',
            borderColor: props.colorScheme === 'whiteScheme' ? 'whiteScheme.600' : 'blackScheme.600',
            color: props.colorScheme === 'whiteScheme' ? 'blackScheme.900' : 'whiteScheme.50', // Cambia a negro en whiteScheme, blanco en blackScheme
          },
          _active: {
            bg: props.colorScheme === 'whiteScheme' ? 'whiteScheme.200' : 'blackScheme.700',
            borderColor: props.colorScheme === 'whiteScheme' ? 'whiteScheme.700' : 'blackScheme.700',
            color: props.colorScheme === 'whiteScheme' ? 'blackScheme.900' : 'whiteScheme.50',
          },
        }),
      },
    },
  }
});

root.render(
  <ChakraProvider theme={theme}>
    <RecoilRoot>
      <AuthProvider>
        <App />
      </AuthProvider>
    </RecoilRoot>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
