import React from 'react';
import { Box, Image, Text, Tag } from '@chakra-ui/react';
import FormattedDate from './FormattedDate';
import utilHandlers from '../../Handlers/utilHandlers';

const AlbumCard = ({ album, onClick }) => {
  const eventFlyer = album?.flyer;

  return (
    <Box
      position="relative"
      w="100%"
      overflow="hidden"
      borderColor="whiteScheme.300"
      borderWidth="2px"
      borderRadius="20px"
      bgColor="whiteScheme.100"
      display="flex"
      key={album.id}
      onClick={onClick}
      opacity={album.active ? 1 : 0.5}
    >
      {eventFlyer && !utilHandlers.isMp4(eventFlyer) && (
        <Image
          src={eventFlyer}
          maxW="33%"
          w="100%"
          h="100%"
          objectFit="cover"
          zIndex="1"
          style={{ aspectRatio: 1080 / 1350 }}
        />
      )}
      {eventFlyer && utilHandlers.isMp4(eventFlyer) && (
        <Box
          as="video"
          autoPlay
          loop
          muted
          playsInline
          maxW="33%"
          w="100%"
          h="100%"
          objectFit="cover"
          zIndex="1"
          style={{ aspectRatio: 1080 / 1350 }}
        >
          <source src={eventFlyer} type="video/mp4" />
        </Box>
      )}
      <Box padding="10px" display="flex" gap="5px" alignItems="start" flexDirection="column">
        <Box display="flex" gap="10px" alignItems="center">
          <Tag bgColor="blackScheme.300" color="whiteScheme.300" fontSize="xs">
            <FormattedDate date={album.start_date_time} />
          </Tag>
        </Box>
        <Box display="flex" flexDirection="column" gap="10px">
          <Text fontSize="2xl" fontWeight="600" lineHeight="1.1em">
            {album.name}
          </Text>
          <Box display="flex" gap="10px" alignItems="center">
            <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="xs">
              {'+' + album.minimumage}
            </Tag>
            <Text fontSize="xs">{album?.venue?.name}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AlbumCard;