import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Select,
  Text,
  Textarea,
  Switch
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa useLocation
import apiHandler from '../Handlers/apiHandler';
import { useRecoilState } from 'recoil';
import { appState, userState } from '../Recoil/atoms';

import Dropzone from './DropZone';

import dateHandler from '../Handlers/dateHandler';

import { AuthContext } from '../context/AuthContext';

const CreateEventForm = () => {

  const { logout } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const location = useLocation(); // Usamos useLocation para obtener el estado
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser] = useRecoilState(userState);

  // Aquí capturamos el evento a duplicar si viene desde la página de detalle
  const eventToDuplicate = location.state?.eventToDuplicate || {};

  const [formData, setFormData] = useState({
    name: eventToDuplicate.name || '',
    start_date_time: eventToDuplicate.start_date_time || '',
    end_date_time: eventToDuplicate.end_date_time || '',
    flyer: eventToDuplicate.flyer || '',
    dresscode: eventToDuplicate.dresscode || '',
    minimumage: eventToDuplicate.minimumage || '',
    description: eventToDuplicate.description || '',
    guest_list_info: eventToDuplicate.guest_list_info || '',
    guest_until: eventToDuplicate.guest_until || '',
    free_until: eventToDuplicate.free_until || '',
    tickets_link: eventToDuplicate.tickets_link || '',
    guest_list: eventToDuplicate.guest_list !== undefined ? eventToDuplicate.guest_list : true,
    sold_out: eventToDuplicate.sold_out !== undefined ? eventToDuplicate.sold_out : false,
    active: eventToDuplicate.active !== undefined ? eventToDuplicate.active : false, // Añadido el valor 'active'
    venueId: eventToDuplicate.venueId || '',
    promoterIds: eventToDuplicate.promoterIds || '',
  });

  const [venues, setVenues] = useState([]);
  const [promoters, setPromoters] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser.role === 'masteruser' || currentUser.role === 'admin') {
          const venuesData = await apiHandler.getAccessibleVenues(logout);
          const promotersData = await apiHandler.getAccessiblePromoters(logout);
          setVenues(venuesData);
          setPromoters(promotersData);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // Verificar si el campo es de tipo datetime-local
    if (type === 'datetime-local') {
      // Convertir el valor al formato completo (incluyendo segundos y milisegundos)
      const selectedDate = new Date(value);
      setFormData({
        ...formData,
        [name]: selectedDate.toISOString(), // Guardar la fecha completa en formato UTC
      });
    } else {
      // Para otros tipos de inputs (incluidos checkboxes)
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.venueId || !formData.promoterIds) {
      alert('Debes seleccionar un Venue y un Promotor.');
      return;
    }

    const newUser = await createEvent(formData);
    window.location.href = '/events';
  };

  const createEvent = async (formData) => {
    try {
      setLoading(true);
      const user = await apiHandler.createEvent(formData, logout);
      if (user.error) {
        alert(user.error);
        setLoading(false);
        return user.error;
      }
      alert('Evento creado correctamente');
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleFileSubmit = async (dropFile) => {
    setLoading(true);
    const fileForm = new FormData();
    await fileForm.append('file', dropFile[0]);

    try {
      const response = await apiHandler.uploadFile(fileForm, logout);
      await setFormData({
        ...formData,
        flyer: response.filePath,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Box
      p="10px"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
    >
      <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">
        {eventToDuplicate.name ? 'DUPLICAR EVENTO' : 'CREAR EVENTO'}
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="20px">
          {/* Nuevo Switch para el valor 'active' */}
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="active" mb="0">
              Evento publicado
            </FormLabel>
            <Switch
              id="active"
              name="active"
              isChecked={formData.active}
              onChange={handleChange}
              colorScheme="blackScheme"
            />
          </FormControl>

          <FormControl id="name">
            <FormLabel>Nombre</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="start_date_time">
            <FormLabel>Fecha de inicio</FormLabel>
            <Input
              type="datetime-local"
              name="start_date_time"
              value={dateHandler.toInputDate(formData.start_date_time)}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="end_date_time">
            <FormLabel>Fecha de fin</FormLabel>
            <Input
              type="datetime-local"
              name="end_date_time"
              value={dateHandler.toInputDate(formData.end_date_time)}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="file" isRequired>
            <FormLabel>Flyer</FormLabel>
            <Dropzone onDrop={handleFileSubmit} />
          </FormControl>

          <FormControl id="dresscode">
            <FormLabel>Código de vestimenta</FormLabel>
            <Input
              type="text"
              name="dresscode"
              value={formData.dresscode}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="minimumage">
            <FormLabel>Edad mínima</FormLabel>
            <Input
              type="number"
              name="minimumage"
              value={formData.minimumage}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="description">
            <FormLabel>Descripción</FormLabel>
            <Textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="guest_list_info">
            <FormLabel>Información de lista de invitados</FormLabel>
            <Textarea
              name="guest_list_info"
              value={formData.guest_list_info}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="guest_list_closes">
            <FormLabel>Cierre lista</FormLabel>
            <Input
              type="datetime-local"
              name="guest_list_closes"
              value={dateHandler.toInputDate(formData.guest_list_closes)}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="guest_until">
            <FormLabel>Hora fin lista descuento</FormLabel>
            <Input
              type="time"
              name="guest_until"
              value={formData.guest_until}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="free_until">
            <FormLabel>Hora fin lista gratis</FormLabel>
            <Input
              type="time"
              name="free_until"
              value={formData.free_until}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="tickets_link">
            <FormLabel>Enlace de entradas</FormLabel>
            <Input
              type="text"
              name="tickets_link"
              value={formData.tickets_link}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="sold_out" mb="0">
              Sold Out
            </FormLabel>
            <Switch
              id="sold_out"
              name="sold_out"
              isChecked={formData.sold_out}
              onChange={handleChange}
              colorScheme="blackScheme"
            />
          </FormControl>
          
          <FormControl id="promoterIds" isRequired>
            <FormLabel>Grupo Promotor</FormLabel>
            <Select
              name="promoterIds"
              value={formData.promoterIds}
              onChange={handleChange}
              placeholder="Selecciona un Promotor"
            >
              {promoters?.map(promoter => (
                <option key={promoter.id} value={promoter.id}>{promoter.name}</option>
              ))}
            </Select>
          </FormControl>

          <FormControl id="venueId" isRequired>
            <FormLabel>Local</FormLabel>
            <Select
              name="venueId"
              value={formData.venueId}
              onChange={handleChange}
              placeholder="Selecciona un Local"
            >
              {venues?.map(venue => (
                <option key={venue.id} value={venue.id}>{venue.name}</option>
              ))}
            </Select>
          </FormControl>

          <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme'>
            {eventToDuplicate.name ? 'Duplicar evento' : 'Crear evento'}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default CreateEventForm;