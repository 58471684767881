import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import LoginForm from '../Components/LoginForm';
import RegisterForm from '../Components/RegisterForm';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';

import { userState } from '../Recoil/atoms';
import RequestPasswordReset from '../Components/RequestPasswordReset';
import ResetPassword from '../Components/ResetPassword';

import { AuthContext } from '../context/AuthContext';

const PasswordResetPage = () => {

  const { logout } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [whatToDo, setWhatToDo] = useState('login');

  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    if(user) {
      window.location.href = '/';
    }
  }, [user])

  return (
    <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      {
        token ? <ResetPassword /> : <RequestPasswordReset />
      }
    </Box>
  );
};

export default PasswordResetPage;