import React, { useState, useCallback, useEffect, useContext } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Text, Image, SimpleGrid, IconButton, CircularProgress } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { isMp4 } from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

const DropZone = ({ onDrop, accept, onRemove = () => {}, existingFiles = [], onRemoveExisting = () => {}, multiple = true, loading={}, setFileLoading }) => {
  
  const { logout } = useContext(AuthContext);

  const [files, setFiles] = useState([]);
  const [existingPreviews, setExistingPreviews] = useState([]);

  const defaultAccept = {
    'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.webp'],
    'video/*': ['.mp4', '.mov', '.wmv', '.avi', '.mkv', '.webm']
  };

  // Cargar archivos existentes
  useEffect(() => {
    if (existingFiles.length > 0) {
      setExistingPreviews(
        existingFiles?.map((file) => ({
          id: file.id,
          preview: file.url
        }))
      );
    }
    
  }, [existingFiles]);

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles?.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      
      if (!multiple) {
        setFiles(newFiles); // Reemplazar archivos si solo se permite uno
      } else {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Añadir archivos si se permiten múltiples
      }

      onDrop(acceptedFiles); // Notificar al padre sobre los archivos subidos
    },
    [onDrop, multiple]
  );

  const handleRemoveFile = (file) => {
    setFiles((prevFiles) => prevFiles?.filter((f) => f !== file));
    onRemove(file); // Notificar al padre sobre la eliminación
  };

  const handleRemoveExistingFile = (file, e) => {
    e.stopPropagation(); // Evitar que se dispare el evento de la Dropzone
    setExistingPreviews((prevPreviews) => prevPreviews?.filter((f) => f.id !== file.id));
    onRemoveExisting(file); // Notificar al padre sobre la eliminación de archivos existentes
  };

  return (
    <Dropzone onDrop={onDropCallback} accept={accept || defaultAccept} multiple={multiple}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Box
          {...getRootProps()}
          p={6}
          borderWidth={2}
          borderRadius="md"
          borderColor={isDragActive ? 'blue.300' : 'gray.300'}
          borderStyle="dashed"
          textAlign="center"
          cursor="pointer"
          _hover={{ borderColor: 'blue.300' }}
        >
          <input {...getInputProps()} />
          {!files.length && !existingPreviews.length ? (
            <Text>
              {isDragActive
                ? 'Suelta los archivos aquí...'
                : `Arrastra y suelta los archivos aquí, o haz clic para seleccionar${multiple ? '' : ' un archivo'}`}
            </Text>
          ) : (
            <SimpleGrid columns={{ base: 3, md: 4 }} spacing={4}>
              {/* Previsualizar archivos existentes */}
              {existingPreviews?.map((file) => (
                <Box key={file.id} position="relative">
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    colorScheme="whiteScheme"
                    position="absolute"
                    top="5px"
                    right="5px"
                    zIndex="2"
                    borderRadius="full"
                    borderColor="whiteScheme.600"
                    borderWidth="1px"
                    onClick={(e) => handleRemoveExistingFile(file, e)} // Evitar propagación
                  />
                  {isMp4(file.preview) ? (
                    <Box
                      as="video"
                      autoPlay
                      loop
                      muted
                      playsInline
                      boxSize="100%"
                      objectFit="cover"
                      borderRadius="md"
                      style={{ aspectRatio: '1/1' }}
                    >
                      <source src={file.preview} type="video/mp4" />
                    </Box>
                  ) : (
                    <Image
                      src={file.preview}
                      alt={`Imagen existente ${file.id}`}
                      boxSize="100%"
                      objectFit="cover"
                      borderRadius="md"
                      style={{ aspectRatio: '1/1' }}
                    />
                  )}
                </Box>
              ))}

              {/* Previsualizar archivos nuevos */}
              {multiple && files?.map((file) => (
                <Box key={file.name} position="relative" opacity={loading[file.name] ? 0.5 : 1}>
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    colorScheme="whiteScheme"
                    position="absolute"
                    top="5px"
                    right="5px"
                    zIndex="2"
                    borderRadius="full"
                    borderColor="whiteScheme.600"
                    borderWidth="1px"
                    onClick={(e) => {
                      e.stopPropagation(); // Evitar que abra el diálogo de archivos
                      handleRemoveFile(file);
                    }}
                  />
                  {isMp4(file.preview) ? (
                    <Box
                      as="video"
                      autoPlay
                      loop
                      muted
                      playsInline
                      boxSize="100%"
                      objectFit="cover"
                      borderRadius="md"
                      style={{ aspectRatio: '1/1' }}
                    >
                      <source src={file.preview} type="video/mp4" />
                    </Box>
                  ) : (
                    <Image
                      src={file.preview}
                      alt={file.name}
                      boxSize="100%"
                      objectFit="cover"
                      borderRadius="md"
                      style={{ aspectRatio: '1/1' }}
                    />
                  )}

                  {/* Mostrar un indicador de carga si el archivo está subiendo */}
                  {loading[file.name] && (
                    <Box position="absolute" top="0" left="0" right="0" bottom="0" display="flex" justifyContent="center" alignItems="center">
                      <CircularProgress isIndeterminate color="whiteScheme.700" />
                    </Box>
                  )}
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

export default DropZone;