import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Text,
  Image,
  Tag,
  Input,
  FormControl,
  TagLeftIcon,
  Box,
  IconButton,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import { toPng } from 'html-to-image';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { PDFDocument, rgb } from 'pdf-lib';

import apiHandler from '../Handlers/apiHandler';
import { userState, eventState, appState, ticketState, refState, ticketsState } from '../Recoil/atoms';
import dateHandler from '../Handlers/dateHandler';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { MdLocationOn } from "react-icons/md";
import { PiTShirt } from "react-icons/pi";
import LoginPage from './LoginPage';

import { AuthContext } from '../context/AuthContext';

function TicketPage( { customButton } ) {

    const { logout } = useContext(AuthContext);
    
    const { ticketId } = useParams();

    const baseURL = 'https://api.myguestlists.com';

    const [event, setEvent] = useRecoilState(eventState);
    const [user, setUser] = useRecoilState(userState);
    const [isLoading, setLoading] = useRecoilState(appState);
    const [ticket, setTicket] = useRecoilState(ticketState);
    const [ref, setRef] = useRecoilState(refState);
    const [tickets, setTickets] = useRecoilState(ticketsState);
    const [doReload, setDoReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [isQrLoaded, setQrLoaded] = useState(false);

    const navigate = useNavigate();

    const fetchTicket = async (ticketId) => {
        try {
            setLoading(true);
            const ticket = await apiHandler.getTicket(ticketId, logout);
            await setTicket(ticket);
            setLoading(false);
        } catch (error) {
            console.error(error);
            await setTicket(null);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (ticketId) {
            fetchTicket(ticketId);
        }
    }, []);

    useEffect(() => {
        fetchTicket(ticketId);
    }, [ticketId]);

    const downloadPDF = async () => {
        const pdfUrl = `https://api.myguestlists.com/public/pdfs/ticket-${ticket.id}.pdf`;

        // Crear un enlace temporal en el DOM
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `ticket-${ticket.id}.pdf`; // El nombre con el que se descargará el archivo
        link.target = '_blank';

        // Simular un clic en el enlace
        document.body.appendChild(link);
        link.click();

        // Remover el enlace temporal del DOM
        document.body.removeChild(link);

    };

    return (
        <>
            <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
                {
                    user && ticket && ticket.id ? (
                        <>
                            <div id="qrticket" style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Text textAlign="center">Muestra este QR en la puerta para {ticket?.type === 'discount' ? 'obtener tu descuento' : 'validar tu entrada'}</Text>
                                <Image id="qrimage" src={`${baseURL}/qrs/ticket-${ticket.id}.png`} width='100%' onLoad={() => setQrLoaded(true)} />
                                <Text textAlign="center" fontWeight="600" fontSize="md">{ticket?.event?.name}</Text>
                                {
                                    ticket?.free ? <>
                                        <Tag bgColor="whiteScheme.400" color='blackScheme.400' fontSize="2xs">
                                            Free Access
                                        </Tag>
                                        <Text whiteSpace="pre-wrap" textAlign="center" fontSize="xs" marginTop="20px">Acceso gratuito hasta las {dateHandler.toTime(event?.venue?.free_until)}h</Text>
                                    </> : <>
                                        <Text textAlign="center" fontSize="sm">{ticket?.name}</Text>
                                        <Text whiteSpace="pre-wrap" textAlign="center" fontSize="xs">{ticket?.description}</Text>
                                    </>
                                }
                            </div>
                            <Button borderRadius="full" colorScheme="blackScheme" isDisabled={!isQrLoaded} onClick={() => downloadPDF()}>Descargar QR</Button>
                        </>
                    ) : (
                        <LoginPage />
                    )
                }
            </Box>
        </>
    );
}

export default TicketPage;