import React, { createContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../Recoil/atoms';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useRecoilState(userState);

    const logout = () => {
        setUser(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
    };

    // Opcionalmente, para sincronizar el estado con localStorage
    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    return (
        <AuthContext.Provider value={{ user, setUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};