import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { brandState, appState, userState, refState } from '../Recoil/atoms';

import { Box, Image, Card, CardHeader, CardBody, CardFooter, Stack, Button, useTheme } from '@chakra-ui/react';

import apiHandler from '../Handlers/apiHandler';
import utilHandlers from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

const Home = (props) => {

    const { logout } = useContext(AuthContext);

    const [brandData, setBrandData] = useRecoilState(brandState);

    const [user, setUser] = useRecoilState(userState);
    const [ref, setRef] = useRecoilState(refState);
    const [loading, setLoading] = useRecoilState(appState);
    const [phone, setPhone] = useState(null);

    const theme = useTheme();

    const navigate = useNavigate();

    const getPhone = async () => {
        try {
            setLoading(true);
            const phoneData = await apiHandler.getRefPhone(ref, logout);
            
            if(phoneData.phone) {
                setPhone(phoneData.phone);
                setLoading(false);
                return phoneData.phone;
            }
            setLoading(false);

            return null;
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        getPhone();
        setLoading(false);
    }, [brandData]);

    const eventsMedia = brandData?.result?.events_media;
    const picturesMedia = brandData?.result?.pictures_media;

    return (
        <>
            <Box position="relative" w="100%" minH="400px" overflow="hidden" borderRadius="20px">
                {
                    (eventsMedia && !utilHandlers.isMp4(eventsMedia)) && <>
                        <Image src={eventsMedia} position="absolute" w="100%" h="100%" objectFit="cover" zIndex="1" bgColor="blackScheme.400"/>
                    </>
                }
                {
                    (eventsMedia && utilHandlers.isMp4(eventsMedia)) && <>
                        <Box as="video" autoPlay loop muted playsInline position="absolute" w="100%" h="100%" objectFit="cover" zIndex="1" bgColor="blackScheme.400">
                            <source src={eventsMedia} type="video/mp4" />
                        </Box>
                    </>
                }
                <Card w="100%" h="100%" bg="transparent" display="flex" alignItems="center" justifyContent="center" textAlign="center" minH="400px" zIndex="2">
                    <CardHeader>
                        <h2 style={{fontSize: '2rem', fontWeight: '600', color: theme.colors.whiteScheme[500]}}>EVENTS</h2>
                        <Stack direction='row' spacing={4} align='center'>
                            <Button borderRadius="20px" variant='solid' colorScheme='whiteScheme' onClick={() => navigate('/events')}>Ver Eventos</Button>
                            <Button borderRadius="20px" variant='outline' colorScheme='whiteScheme' as="a" target='_blank' href={'https://wa.me/34' + (phone ?? brandData?.result?.vip_tables_contact) }>Vip Tables</Button>
                        </Stack>
                    </CardHeader>
                </Card>
            </Box>
            <Box position="relative" w="100%" minH="400px" overflow="hidden" borderRadius="20px">
                {
                    (picturesMedia && !utilHandlers.isMp4(picturesMedia)) && <>
                        <Image src={picturesMedia} position="absolute" w="100%" h="100%" objectFit="cover" zIndex="1" bgColor="blackScheme.400"/>
                    </>
                }
                {
                    (picturesMedia && utilHandlers.isMp4(picturesMedia)) && <>
                        <Box as="video" autoPlay loop muted playsInline position="absolute" w="100%" h="100%" objectFit="cover" zIndex="1" bgColor="blackScheme.400">
                            <source src={picturesMedia} type="video/mp4" />
                        </Box>
                    </>
                }
                <Card w="100%" h="100%" bg="transparent" display="flex" alignItems="center" justifyContent="center" textAlign="center" minH="400px" zIndex="2">
                    <CardHeader>
                        <h2 style={{fontSize: '2rem', fontWeight: '600', color: theme.colors.whiteScheme[500]}}>FOTOS</h2>
                        <Stack direction='row' spacing={4} align='center'>
                            <Button borderRadius="20px" variant='solid' colorScheme='whiteScheme' onClick={() => navigate('/albums')}>Ver Fotos</Button>
                        </Stack>
                    </CardHeader>
                </Card>
            </Box>
        </>
    );
}

export default Home;