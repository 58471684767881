const toEsDate = (isoDate, toReturn=null) => {
    // Crear un objeto Date ajustado a la zona horaria de España
    const date = new Date(isoDate);

    // Array para los días de la semana y meses en inglés
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    // Convertir la fecha a la hora local de España
    const options = {
        timeZone: 'Europe/Madrid',
        hour12: false, // Formato 24 horas
    };

    // Obtener las partes de la fecha ajustadas a la zona horaria de España
    const dayOfWeek = date.toLocaleString('en-GB', { ...options, weekday: 'short' }).split(',')[0].trim().toUpperCase();
    const day = String(date.toLocaleString('en-GB', { ...options, day: '2-digit' }).split(',')[0].trim()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.toLocaleString('en-GB', { ...options, year: 'numeric' }).split(',')[0].trim();

    // Formatear la fecha sin puntos ni comas y con el día en negrita
    const formattedDate = `${dayOfWeek}. <b>${day}</b> ${month}.`;

    switch (toReturn) {
        case 'weekDay':
            return dayOfWeek;
        case 'day':
            return day;
        case 'month':
            return month;
        case 'year':
            return year;
        default:
            return formattedDate;
    }
}

const toTime = (input) => {
    if (input.includes('T')) {
        // Caso para fechas ISO
        const date = new Date(input);

        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Formato 24 horas
            timeZone: 'Europe/Madrid' // Especificar la zona horaria de España
        };

        return date.toLocaleTimeString('es-ES', options);
    } else {
        // Caso para formato "HH:MM:SS"
        const timeParts = input.split(':');
        return `${timeParts[0]}:${timeParts[1]}`; // Retorna solo HH:MM
    }
}

const nowUTCDate = () => {
    const now = new Date();
    const isoDateTimeString = now.toISOString()
    return isoDateTimeString;
}

const nowUTCTime = () => {
    const now = new Date();

    const hours = String(now.getHours()).padStart(2, '0');    // Asegura que sea de dos dígitos
    const minutes = String(now.getMinutes()).padStart(2, '0'); // Asegura que sea de dos dígitos
    const seconds = String(now.getSeconds()).padStart(2, '0'); // Asegura que sea de dos dígitos

    const timeString = `${hours}:${minutes}:${seconds}`;

    return timeString;
}

const toInputDate = (date) => {
    if(!date) {
        return '';
    }
    const utcDate = new Date(date); // Fecha en UTC
    const localDate = new Date(utcDate.getTime()); // Crear una nueva fecha en hora local del navegador

    // Formatear la fecha en 'YYYY-MM-DDTHH:MM' para el input datetime-local
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Mes (0-indexado)
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

const dateHandler = {
    toEsDate,
    toTime,
    nowUTCDate,
    nowUTCTime,
    toInputDate
}

export default dateHandler;