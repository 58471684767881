import logo from './logo.svg';
import './App.css';

import { createBrowserRouter, RouterProvider, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useContext, useState } from 'react';
import { useRecoilState } from 'recoil';

import LoginPage from './Pages/LoginPage';
import HomePage from './Pages/HomePage';
import EventsPage from './Pages/EventsPage';
import Loading from './Components/Loading';
import EventPage from './Pages/EventPage';

import { Box, Container, Flex, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { brandState, appState, pageState, userState, refState } from './Recoil/atoms';

import apiHandler from './Handlers/apiHandler';
import CreateUserPage from './Pages/CreateUserPage';
import CreateEventPage from './Pages/CreateEventPage';
import TicketsPage from './Pages/TicketsPage';
import PasswordResetPage from './Pages/PasswordResetPage';
import VerifyEmailPage from './Pages/VerifyEmailPage';
import MyTickets from './Pages/MyTickets';
import TicketPage from './Pages/TicketPage';
import MyUsers from './Pages/MyUsers';
import EditUserPage from './Pages/EditUserPage';
import EditEventPage from './Pages/EditEventPage';
import CreateVenuePage from './Pages/CreateVenuePage';
import MyVenues from './Pages/MyVenues';
import EditVenuePage from './Pages/EditVenuePage';
import AlbumsPage from './Pages/AlbumsPage';
import AlbumPage from './Pages/AlbumPage';

import { useMetaPixel } from './Pixels/useMetaPixel';
import StatsPage from './Pages/StatsPage';

import { AuthContext } from './context/AuthContext';

function App() {

  const { logout } = useContext(AuthContext);

  window.addEventListener('beforeunload', () => setLoading(true));
  window.addEventListener('load',  () => setLoading(false));

  const router = createBrowserRouter([
    {
      path: '',
      element: <HomePage />
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/events',
      element: <EventsPage />
    },
    {
      path: '/event/:eventId',
      element: <EventPage />
    },
    {
      path: '/create-user',
      element: <CreateUserPage />
    },
    {
      path:'/create-event',
      element: <CreateEventPage />
    },
    {
      path: '/tickets/:eventId',
      element: <TicketsPage />
    },
    {
      path: '/reset-password',
      element: <PasswordResetPage />
    },
    {
      path: '/verify-email/:token',
      element: <VerifyEmailPage />
    },
    {
      path: '/my-tickets',
      element: <MyTickets />
    },
    {
      path: '/ticket/:ticketId',
      element: <TicketPage />
    },
    {
      path: '/my-users',
      element: <MyUsers />
    },
    {
      path: '/edit-user',
      element: <EditUserPage />
    },
    {
      path: '/edit-event',
      element: <EditEventPage />
    },
    {
      path: '/create-venue',
      element: <CreateVenuePage />
    },
    {
      path: '/my-venues',
      element: <MyVenues />
    },
    {
      path: '/edit-venue',
      element: <EditVenuePage />
    },
    {
      path: '/stats/:eventId',
      element: <StatsPage />
    },
    {
      path: '/albums',
      element: <AlbumsPage />
    },
    {
      path: '/album/:albumId',
      element: <AlbumPage />
    }
  ]);


  const [brandData, setBrandData] = useRecoilState(brandState);
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentPage, setPage] = useRecoilState(pageState);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [currentRef, setCurrentRef] = useRecoilState(refState);

  const currentUrl = window.location.href;

  const trackEvent = useMetaPixel(brandData?.result?.fb_pixel_id);

  const addQuery = (ref) => {
    const actualUrl = window.location.href;
    if(!actualUrl?.split('?')[1]?.length) {
      window.location.href = window.location.href + '?ref=' + ref;
    }
    /*
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    */
  };

  useEffect(() => {
    trackEvent('PageView', {
      email: currentUser?.email,
      phone: currentUser?.phone,
      first_name: currentUser?.name,
      last_name: currentUser?.surnames,
      url: window.location.href,
      refId: currentRef,
      client_user_agent: navigator.userAgent
    });
  }, [currentUrl]);

  /*
  useEffect(() => {
    if(trackEvent) {
      trackEvent('PageView', {
        email: currentUser?.email,
        phone: currentUser?.phone,
        first_name: currentUser?.name,
        last_name: currentUser?.surnames,
        url: window.location.href,
        refId: currentRef,
        client_user_agent: navigator.userAgent
      });
    }
    setLastUrl(window.location.href);
  }, [brandData, currentUser, currentRef, lastUrl]);
  */

  const getRef = async () => {
    // Obtener la parte de la URL que contiene los parámetros de consulta
    const search = window.location.search;

    const firstVisit = sessionStorage.getItem('firstVisit');
  
    const ref = new URLSearchParams(search).get('ref');

    if(ref) {
      await setCurrentRef(ref);
      addQuery(ref);
    }

    if(!ref && !firstVisit) {
      await setCurrentRef(null);
    }

    if (!firstVisit) {
      // Establece la marca "firstVisit" en el Session Storage
      sessionStorage.setItem('firstVisit', 'true');
    }
  }


  const getBrand = async () => {
    try {
      setLoading(true);
      const hostname = window.location.hostname;

      const parts = hostname.split('.');

      const subdomain = parts.length == 3 ? parts[0] : 'bocca';

      const brand = await apiHandler.getBrand(subdomain, logout);

      await setBrandData(brand);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const setCurrentPage = async () => {
    await setPage(window.location.pathname);
  }

  const getUserIfExists = async () => {
    setLoading(true);
    const accessToken = await localStorage.getItem('accessToken');
    const refreshToken = await localStorage.getItem('refreshToken');
    const rawUser = await localStorage.getItem('user');
    const user = JSON.parse(rawUser);

    if(accessToken && refreshToken && user) {
      await setCurrentUser(user);
    } else {
      await setCurrentUser(null);
    }
    setLoading(false);
  }

  const logOut = async () => {
    setLoading(true);
    try {
      await apiHandler.logOut(logout);
      await setCurrentUser(null);
      localStorage.clear();
      setLoading(false);
      window.location.href = '/login';
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getUserIfExists();
    getBrand();
    setCurrentPage();
    getRef();
  }, []);


  const hrefActual = window.location.href;

  useEffect(() => {
    if(currentRef) {
      addQuery(currentRef);
    }
  }, [currentRef, hrefActual]);


  window.addEventListener('beforeunload', () => setLoading(true));
  window.addEventListener('load',  () => setLoading(false));

  return (
      <>
        {
          isLoading ? <Loading /> : <></>
        }
        <Container maxW="ld" padding="0px 10px 10px 10px" bgColor="whiteScheme.100" minH="100vh" display="flex" flexDir="column" alignItems="center">
          <Flex gap={3} direction="column" p='10px 0' style={{position: 'sticky', top: 0, zIndex: 50}} width="100%">
            <Box bgColor="whiteScheme.200" color="blackScheme.400" p='10px 15px' borderRadius="full" justifyContent='space-between' display='flex' alignItems='center' className='glass' borderColor="whiteScheme.400" borderWidth="1px">
              {
                brandData && brandData.result ? 
                  <Image src={brandData?.result?.logo_media} width='100px' onClick={() => window.location.href = '/'}/> :
                  <></>
              }
              <Menu isLazy>
                <MenuButton>Menu</MenuButton>
                <MenuList bgColor="whiteScheme.200" borderColor="whiteScheme.400">
                  {/* MenuItems are not rendered unless Menu is open */}
                  <MenuItem bgColor="whiteScheme.200" onClick={() => window.location.href = '/events'}>Eventos</MenuItem>
                  <MenuItem bgColor="whiteScheme.200" onClick={() => window.location.href = '/albums'}>Fotos</MenuItem>
                  <MenuItem bgColor="whiteScheme.200" onClick={() => alert('Disponible próximamente')}>Restaurante</MenuItem>
                  {
                    currentUser ? <>
                      {
                        (currentUser.role == 'masteruser' || currentUser.role == 'admin') && <MenuItem bgColor="whiteScheme.200" onClick={() => window.location.href = '/create-user'}>Crear Usuario</MenuItem>
                      }
                      {
                        (currentUser.role == 'masteruser' || currentUser.role == 'admin') && <MenuItem bgColor="whiteScheme.200" onClick={() => window.location.href = '/create-event'}>Crear Evento</MenuItem>
                      }
                      {
                        (currentUser.role == 'user') && <MenuItem bgColor="whiteScheme.200" onClick={() => window.location.href = '/my-tickets'}>Mis Tickets</MenuItem>
                      }
                      { (currentUser && currentUser.role != 'user') && 
                        <>
                          <MenuItem bgColor="whiteScheme.200" onClick={() => window.location.href = '/my-users'}>Mis Usuarios</MenuItem>
                          <MenuItem bgColor="whiteScheme.200" onClick={() => {
                                  const currentUrl = `${window.location.origin}`;
                                  const userUrl = `${currentUrl}?ref=${currentUser.id}`
                                  navigator.clipboard.writeText(userUrl);
                              }}>Copiar mi enlace</MenuItem> 
                        </>
                      }
                      <MenuItem bgColor="whiteScheme.200" onClick={() => logOut()}>Cerrar sesión</MenuItem> 
                    </> : <>
                      <MenuItem bgColor="whiteScheme.200" onClick={() => window.location.href = '/login'}>Iniciar sesión</MenuItem>
                    </>
                  }
                </MenuList>
              </Menu>
            </Box>
          </Flex>
          <Flex gap={3} width="100%" direction="column" style={{maxWidth: 767}} margin="0 auto" minHeight="100%">
            <RouterProvider router={router} />
          </Flex>
          <Flex gap={1} width="100%" style={{maxWidth: 767}} p="50px 10px 10px 10px" marginTop="auto" justifyContent="center" alignItems="end" fontSize="xs" cursor="pointer" justifySelf="end">
                <span>Powered by</span><Image src={'https://justforfun.es/wp-content/uploads/2024/06/Group-10.png'} width='80px' objectFit="contain" onClick={() => window.open('https://justforfun.es?utm_medium=myguestlists', '_black')}/>
          </Flex>
        </Container>
      </>
  );
}

export default App;
