import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import apiHandler from '../Handlers/apiHandler';
import { useRecoilState } from 'recoil';
import { appState, messageState } from '../Recoil/atoms';

import { AuthContext } from '../context/AuthContext';

const RequestPasswordReset = () => {
  
  const { logout } = useContext(AuthContext);
  
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentMessage, setCurrentMessage] = useRecoilState(messageState);

  useEffect(() => {
    setCurrentMessage(null);
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiHandler.requestPasswordReset({ email }, logout);
      if(!response.success&& response.error) {
        setError(response.error);
        setCurrentMessage(null);
      } else {
        setCurrentMessage(response.data.message);
        setError('');
      }
    } catch (err) {
      console.error(err);
      setError(err.error || 'Error al solicitar el restablecimiento de contraseña');
      setCurrentMessage(null);
    }
  };

  return (
    <Box p="10px" display="flex" flexDir="column" alignItems="center" gap="20px">
      <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">Restablecer Contraseña</Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="20px">
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          {currentMessage && <Text color="green.500">{currentMessage}</Text>}
          {error && <Text color="red.500">{error}</Text>}
          <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme'>
            Enviar enlace de restablecimiento
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default RequestPasswordReset;