import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Text, Spinner, Button } from '@chakra-ui/react';
import apiHandler from '../Handlers/apiHandler';
import { useRecoilState } from 'recoil';

import { userState } from '../Recoil/atoms';

import { AuthContext } from '../context/AuthContext';

const VerifyEmailPage = () => {

  const { logout } = useContext(AuthContext);
  
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useRecoilState(userState);

  const { token } = useParams();

  const verifyEmail = async () => {
    setLoading(true);
    try {
      const response = await apiHandler.verifyEmail(token, logout);
      if(response.error) {
        setMessage(response.error);
      } else {
        setMessage(response.message);
        if(response.message) {
          await setCurrentUser(response);
        }
      }
      setLoading(false);

    } catch (error) {
      setMessage(error.response?.data?.error || 'Error al verificar la cuenta');
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, [location]);

  return (
    <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <Text fontSize="xl" fontWeight="600" textAlign="center">
            {message}
          </Text>
          <Button borderRadius="full" colorScheme="blackScheme" onClick={() => navigate('/login')}>Volver al inicio</Button>
        </>
      )}
    </Box>
  );
};

export default VerifyEmailPage;