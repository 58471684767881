import React from 'react';
import { Box, Image, Text, Tag } from '@chakra-ui/react';
import dateHandler from '../../Handlers/dateHandler';

const EventItem = ({ event, navigate }) => {
    const { start_date_time, end_date_time, flyer, name, active, minimumage, venue } = event;

    const formatDate = (date, format) => {
        const weekDay = dateHandler.toEsDate(date, 'weekDay');
        const day = dateHandler.toEsDate(date, 'day');
        const month = dateHandler.toEsDate(date, 'month');
        const year = dateHandler.toEsDate(date, 'year');
        return format === 'full' ? `${weekDay} ${day} ${month} ${year}` : `${weekDay} ${day}`;
    };

    return (
        <Box
            key={event.id}
            position="relative"
            w="100%"
            overflow="hidden"
            borderColor="whiteScheme.300"
            borderWidth="2px"
            borderRadius="20px"
            bgColor="whiteScheme.100"
            display="flex"
            onClick={() => navigate(`/event/${event.id}`)}
            opacity={active ? 1 : 0.5}
        >
            {flyer && (
                flyer.endsWith('.mp4') ? (
                    <Box as="video" autoPlay loop muted playsInline maxW="33%" w="100%" h="100%" objectFit="cover" style={{ aspectRatio: 1080 / 1350 }}>
                        <source src={flyer} type="video/mp4" />
                    </Box>
                ) : (
                    <Image src={flyer} maxW="33%" w="100%" h="100%" objectFit="cover" style={{ aspectRatio: 1080 / 1350 }} />
                )
            )}
            <Box padding="10px" display="flex" flexDirection="column" gap="5px">
                <Box display="flex" gap="10px" alignItems="center">
                    <Tag bgColor="blackScheme.300" color="whiteScheme.300" fontSize="xs">{formatDate(start_date_time, 'full')}</Tag>
                    <Text fontSize="xs">{dateHandler.toTime(start_date_time)} - {dateHandler.toTime(end_date_time)}</Text>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                    <Text fontSize="2xl" fontWeight="600" lineHeight="1.1em">{name}</Text>
                    <Box display="flex" gap="10px" alignItems="center">
                        <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="xs">{"+" + minimumage}</Tag>
                        <Text fontSize="xs">{venue?.name}</Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default EventItem;