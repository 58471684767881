import React, { useEffect } from 'react';
import {
  Box,
  useColorModeValue,
  Text,
  IconButton,
  Tag,
  TagLeftIcon
} from '@chakra-ui/react';

import { useRecoilState } from 'recoil';

import { useNavigate } from 'react-router-dom';

import { userState, eventToEditState } from '../Recoil/atoms'; // Importa eventToEditState
import EditEventForm from '../Components/EditEventForm'; // Importa el componente EditEventForm
import dateHandler from '../Handlers/dateHandler';

import { ArrowBackIcon } from '@chakra-ui/icons';
import { MdLocationOn } from 'react-icons/md'; 
import AlbumUploader from '../Components/AlbumUploader';


const EditEventPage = () => {
  const [user] = useRecoilState(userState);
  const [eventToEdit, setEventToEdit] = useRecoilState(eventToEditState); // Obtener el evento a editar

  const navigate = useNavigate();

  useEffect(() => {
    if (!eventToEdit) {
      // Manejar el caso en que no hay evento seleccionado para editar
      console.error("No event selected for editing");
      // Aquí podrías redirigir a otra página o mostrar un mensaje de error.
    }
  }, [eventToEdit]);

  const getFormattedDate = (event) => {
    const weekDay = dateHandler.toEsDate(event.start_date_time, 'weekDay');
    const day = dateHandler.toEsDate(event.start_date_time, 'day');
    const month = dateHandler.toEsDate(event.start_date_time, 'month');
    const year = dateHandler.toEsDate(event.start_date_time, 'year');

    const FormattedDate = () => {
        return (
            <span>
                {weekDay} <strong>{day}</strong> {month}. {year}
            </span>
        );
    }

    return FormattedDate();
}

  return (
    <Box padding="10px" w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" paddingBottom="50px">
      <Box display="flex" gap="10px">
        <IconButton
          size="md"
          icon={<ArrowBackIcon />}
          colorScheme="blackScheme"
          variant="outline"
          onClick={() => navigate(`/event/${eventToEdit.id}`)}
        />
        <Box display="flex" flexDirection="column" gap="0px" alignItems="start">
          <Text fontSize="lg" fontWeight="600">
            {eventToEdit?.name} <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="2xs">
              <TagLeftIcon as={MdLocationOn} />
                {eventToEdit?.venue?.name}
              </Tag>
          </Text>
          <Text fontSize="xs">{getFormattedDate(eventToEdit)}</Text>
        </Box>
      </Box>
      {
        user && (user.role === 'masteruser' || user.role === 'admin') ? (
          eventToEdit ? (
            <>
              <EditEventForm event={eventToEdit} />
              <AlbumUploader event={eventToEdit} existingImages={eventToEdit.images} />
            </>
          ) : (
            <Text>No hay evento seleccionado para editar.</Text>
          )
        ) : (
          <Text>No tienes permisos para editar eventos.</Text>
        )
      }
    </Box>
  );
};

export default EditEventPage;