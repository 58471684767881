// useMetaPixel.js
import { useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Para generar event_id únicos
import sha256 from 'crypto-js/sha256'; // Para hashear datos sensibles

import apiHandler from '../Handlers/apiHandler';

import { AuthContext } from '../context/AuthContext';

// Hook para usar el Meta Pixel
export const useMetaPixel = (pixelId) => {

  const { logout } = useContext(AuthContext);
  
  useEffect(() => {
    // Inicializar el Meta Pixel cuando el componente se monta
    if (typeof window.fbq === 'undefined') {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      window.fbq('init', pixelId);
    }
  }, [pixelId]);

  // Función para trackear eventos
  const trackEvent = async (eventName, customData = {}, customEvent = false) => {
    const eventId = uuidv4(); // Generar un event_id único

    // Hashear datos sensibles, si existen
    const emailHash = customData.email ? await sha256(customData.email).toString() : null;
    const phoneHash = customData.phone ? await sha256(customData.phone).toString() : null;
    const firstNameHash = customData.first_name ? await sha256(customData.first_name).toString() : null;
    const lastNameHash = customData.last_name ? await sha256(customData.last_name).toString() : null;

    // Obtener las cookies del navegador, si están disponibles
    const fbpCookie = document.cookie.split('; ').find(row => row.startsWith('_fbp='))?.split('=')[1] || null;
    const fbcCookie = document.cookie.split('; ').find(row => row.startsWith('_fbc='))?.split('=')[1] || null;

    // Enviar el evento al Meta Pixel (del lado del cliente)
    const trackingType = customEvent ? 'trackCustom' : 'track';

    window.fbq(trackingType, eventName, {
      event_id: eventId,
      email: emailHash,
      phone: phoneHash,
      first_name: firstNameHash,
      last_name: lastNameHash,
      ...customData, // Pasar cualquier dato adicional
    });

    const eventData = {
      eventName,
      eventId,
      userData: {
        email: emailHash,
        phone: phoneHash,
        first_name: firstNameHash,
        last_name: lastNameHash,
        client_user_agent: navigator.userAgent,
        fbp: fbpCookie,
        fbc: fbcCookie
      },
      customData,
      testEventCode: customData.testEventCode ?? null
    }

    const apiEvent = await apiHandler.trackMetaEvent(eventData, logout);

    // console.log('apiEvent: ', apiEvent);
  };

  return trackEvent;
};