import React, { useRef, useState, useContext } from 'react';
import { Button, HStack, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react';
import utilHandlers from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

function TicketActions({
  ticket,
  user,
  event,
  deleteTicket,  // función para eliminar el ticket
  checkInTicket,        // función para hacer check-in o check-out
  setFree,              // función para marcar o desmarcar como free
  showDeleteModal = true,   // prop para decidir si mostrar modal de confirmación para eliminar
  showCheckInModal = false, // prop para decidir si mostrar modal de confirmación para check-in
  showSetFreeModal = false  // prop para decidir si mostrar modal de confirmación para setFree
}) {
  
  const { logout } = useContext(AuthContext);

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isCheckInOpen, onOpen: onCheckInOpen, onClose: onCheckInClose } = useDisclosure();
  const { isOpen: isSetFreeOpen, onOpen: onSetFreeOpen, onClose: onSetFreeClose } = useDisclosure();
  const cancelRef = useRef();

  // Manejo de eliminación del ticket
  const handleDelete = () => {
    if (showDeleteModal) {
      onDeleteOpen();
    } else {
      deleteTicket(ticket.id);
    }
  };

  // Manejo de check-in/out del ticket
  const handleCheckIn = () => {
    if (showCheckInModal) {
      onCheckInOpen();
    } else {
      checkInTicket(ticket.id);
    }
  };

  // Manejo de set free/unset free
  const handleSetFree = () => {
    if (showSetFreeModal) {
      onSetFreeOpen();
    } else {
      setFree(ticket.id);
    }
  };

  return (
    <>
      <HStack spacing={4} justifyContent='center'>
        {(user.role === 'masteruser' || user.role === 'admin' || utilHandlers.doesUserCan(user.user_can, 'delticket', 'venue', event.venue.id)) && (
          <Button
            bg="red.800"
            borderRadius="full"
            onClick={handleDelete}
          >
            Eliminar
          </Button>
        )}
        {(user.role === 'masteruser' || user.role === 'admin' || utilHandlers.doesUserCan(user.user_can, 'checkin', 'venue', event.venue.id)) && (
          <Button
            color={ticket.checked_in ? 'blackScheme.200' : 'blackScheme.700'}
            variant="outline"
            borderRadius="full"
            onClick={handleCheckIn}
          >
            {!ticket.checked_in ? 'Check In' : 'Check Out'}
          </Button>
        )}
        {(user.role === 'masteruser' || user.role === 'admin' || utilHandlers.doesUserCan(user.user_can, 'setfree', 'venue', event.venue.id)) && (
          <Button
            onClick={handleSetFree}
            borderRadius="full"
            colorScheme="blackScheme"
          >
            {!ticket.free ? 'Set Free' : 'Unset Free'}
          </Button>
        )}
        {(ticket?.free && user.role !== 'masteruser' && user.role !== 'admin' && !utilHandlers.doesUserCan(user.user_can, 'setfree', 'venue', event.venue.id) && utilHandlers.doesUserCan(user.user_can, 'unsetfree', 'venue', event.venue.id)) && (
          <Button
            onClick={handleSetFree}
            borderRadius="full"
            colorScheme="blackScheme"
          >
            Unset Free
          </Button>
        )}
      </HStack>

      {/* Modal de confirmación para eliminar */}
      {showDeleteModal && (
        <AlertDialog
          isOpen={isDeleteOpen}
          leastDestructiveRef={cancelRef}
          onClose={onDeleteClose}
        >
          <AlertDialogOverlay zIndex={99999}>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirmar Eliminación
              </AlertDialogHeader>
              <AlertDialogBody>
                ¿Estás seguro de que deseas eliminar este ticket? Esta acción no se puede deshacer.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDeleteClose} borderRadius="full">
                  Cancelar
                </Button>
                <Button
                  bg="red.800"
                  onClick={() => {
                    deleteTicket(ticket.id);
                    onDeleteClose();
                  }}
                  ml={3}
                  borderRadius="full"
                >
                  Eliminar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {/* Modal de confirmación para check-in */}
      {showCheckInModal && (
        <AlertDialog
          isOpen={isCheckInOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCheckInClose}
        >
          <AlertDialogOverlay zIndex={99999}>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirmar Check In/Out
              </AlertDialogHeader>
              <AlertDialogBody>
                ¿Estás seguro de que deseas hacer {ticket.checked_in ? 'Check Out' : 'Check In'} de este ticket?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCheckInClose} borderRadius="full">
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    checkInTicket(ticket.id);
                    onCheckInClose();
                  }}
                  ml={3}
                  borderRadius="full"
                  colorScheme="blackScheme"
                >
                  Confirmar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {/* Modal de confirmación para set free/unset free */}
      {showSetFreeModal && (
        <AlertDialog
          isOpen={isSetFreeOpen}
          leastDestructiveRef={cancelRef}
          onClose={onSetFreeClose}
        >
          <AlertDialogOverlay zIndex={99999}>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirmar {ticket.free ? 'Unset Free' : 'Set Free'}
              </AlertDialogHeader>
              <AlertDialogBody>
                ¿Estás seguro de que deseas {ticket.free ? 'quitar el Free' : 'marcar como Free'} este ticket?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onSetFreeClose} borderRadius="full">
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    setFree(ticket.id);
                    onSetFreeClose();
                  }}
                  ml={3}
                  borderRadius="full"
                  colorScheme="blackScheme"
                >
                  Confirmar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
}

export default TicketActions;