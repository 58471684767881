import React, { useState, useEffect, useContext } from 'react';
import { Button, Text, Box, Icon, IconButton, CloseButton, Tag, Flex } from '@chakra-ui/react';
import QrScanner from 'react-qr-scanner';
import apiHandler from '../Handlers/apiHandler';
import utilHandlers from '../Handlers/utilHandlers';
import { appState } from '../Recoil/atoms';

import { useRecoilState } from 'recoil';

import TicketActions from './TicketActions';

import { AuthContext } from '../context/AuthContext';

const QrScannerComponent = ({onClose, onCheckIn, onDelete, onSetFree, user, event}) => {
    
    const { logout } = useContext(AuthContext);

    const [ticketId, setTicketId] = useState(null);
    const [ticket, setTicketData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useRecoilState(appState);

    const handleScan = async (data) => {
        if (data) {
            await setTicketId(data.text);
            const newTicket = await fetchTicketData(data.text);
            if(newTicket?.event?.id != event.id) {
                alert('El ticket escaneado no es válido para este evento');
                await setTicketId(null);
                await setTicketData(null);
            }
        }
    };

    const handleError = (err) => {
        console.error(err);
        setError('Error al escanear el código QR. Por favor, inténtelo de nuevo.');
    };

    const fetchTicketData = async (id) => {
        try {
            setLoading(true);
            const response = await apiHandler.getTicket(id, logout);
            setTicketData(response);
            return response;
        } catch (err) {
            setError('No se pudo obtener la información del ticket.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const resetTicket = () => {
        setTicketId(null);
        setTicketData(null);
    };

    const doOnDelete = async (ticketId) => {
        await onDelete(ticketId);
        await resetTicket();
    }

    const doOnCheckIn = async (ticketId) => {
        await onCheckIn(ticketId);
        await resetTicket();
    }

    const doOnSetFree = async (ticketId) => {
        await onSetFree(ticketId);
        await fetchTicketData(ticketId);
    }

    const getBgColor = (ticket) => {
        if(!ticket) return 'whiteScheme.200';
        return ticket?.checked_in ? 'red.700' : 'whiteScheme.200';
    }

    const previewStyle = {
        height: "100%",
        width: "100%",
        objectFit: 'cover',
        zIndex: '9999'
        /*left: 0*/
    };

    return (
        <>
            <Flex backgroundColor={getBgColor(ticket)} style={{
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    zIndex: 9999,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {
                        !ticket && <>
                            <QrScanner
                                delay={0}
                                onError={handleError}
                                onScan={handleScan}
                                style={previewStyle}
                                constraints={{ video: { facingMode: "environment" } }} // Pasar las restricciones aquí directamente
                            />
                        </>
                    }
                <CloseButton
                color={ticket ? 'blackScheme.900' : 'whiteScheme.200'}
                size='xl'
                    style={{
                        position: 'fixed',
                        right: 20,
                        top: 20,
                        zIndex: 99999
                    }}
                onClick={ticket ? () => setTicketData(null) : () => onClose(false)}
                />
                {
                    ticket && 
                    <>
                        <Box p="20px" backgroundColor={getBgColor(ticket)} position="fixed" bottom="0" width="100%" display="flex" gap="10px" zIndex="99999" flexDir="column" justifyContent="center" alignItems="center">
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding="10px" borderColor="whiteScheme.500" borderWidth="2px" borderRadius="9px" backgroundColor="whiteScheme.200">
                                <Text
                                    fontSize="xl"
                                    fontWeight="600"
                                    display="inline"
                                    cursor="pointer"
                                    textAlign="center"
                                    color="blackScheme"
                                    >
                                    {ticket.user
                                    ? `${ticket?.user?.name} ${ticket?.user?.surnames}`
                                    : ticket.nouser_name}
                                </Text>
                                {ticket.free && (
                                    <Tag bgColor="whiteScheme.400" color='blackScheme.400' fontSize="2xs">
                                    Free Access
                                    </Tag>
                                )}
                            </Box>
                            <Text textAlign="center" fontWeight="600" fontSize="sm" color={ticket.checked_in ? 'whiteScheme.200' : 'blackScheme.900'}>{ticket?.name}</Text>
                            <Text textAlign="center" fontWeight="400" fontSize="xs" color={ticket.checked_in ? 'whiteScheme.200' : 'blackScheme.900'}>{ticket?.event?.name}</Text>
                            <Text textAlign="center" fontWeight="300" fontSize="xs" color={ticket.checked_in ? 'whiteScheme.200' : 'blackScheme.900'}>{ticket?.event?.venue?.name}</Text>
                            
                            
                            <Box gap="20px" display="flex" justifyContent="center" padding="10px" borderRadius="full" borderWidth="2px" borderColor="whiteScheme.400" bg="whiteScheme.400" marginTop="20px">
                                {
                                    ticket && user && <>
                                        <TicketActions
                                            ticket={ticket}
                                            user={user}
                                            event={event}
                                            deleteTicket={doOnDelete}
                                            checkInTicket={doOnCheckIn}
                                            setFree={doOnSetFree}
                                            showDeleteModal={true}  // Mostrar modal de confirmación para eliminar
                                            showCheckInModal={false} // Mostrar modal de confirmación para check-in/out
                                            showSetFreeModal={true} // No mostrar modal de confirmación para set free/unset free
                                        />
                                    </>
                                }
                            </Box>
                        </Box>
                    </>
                }
            </Flex>
        </>
    );
};

export default QrScannerComponent;