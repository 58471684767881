import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  TableContainer,
  Text,
  Box
} from '@chakra-ui/react';
import utilHandlers from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

function TicketStatsTable({ tickets, generateStats, ticketTypes, currentUser, currentEvent }) {
  
  const { logout } = useContext(AuthContext);

  const [stats, setStats] = useState([]);
  const [totals, setTotals] = useState({
    totalCheckedIn: 0,
    totalTickets: 0,
    totalFreeCheckedIn: 0,
    totalFree: 0
  });

  // Generar las estadísticas basadas en los tickets
  useEffect(() => {
    if (tickets.length > 0) {
      const statsData = generateStats(tickets);
      setStats(statsData);

      // Calcular los totales
      const totalCheckedIn = statsData.reduce((sum, referrer) => {
        return sum + Object.values(referrer.types).reduce((subSum, type) => subSum + type.totalCheckedIn, 0);
      }, 0);

      const totalTickets = statsData.reduce((sum, referrer) => {
        return sum + Object.values(referrer.types).reduce((subSum, type) => subSum + type.totalTickets, 0);
      }, 0);

      const totalFreeCheckedIn = statsData.reduce((sum, referrer) => {
        return sum + Object.values(referrer.types).reduce((subSum, type) => subSum + type.totalFreeAndCheckedIn, 0);
      }, 0);

      const totalFree = statsData.reduce((sum, referrer) => {
        return sum + Object.values(referrer.types).reduce((subSum, type) => subSum + type.totalFree, 0);
      }, 0);

      setTotals({
        totalCheckedIn,
        totalTickets,
        totalFreeCheckedIn,
        totalFree
      });
    }
  }, [tickets, generateStats]);

  return (
    <>
        <Box padding="10px" w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
            <Text fontSize="md" fontWeight="bold" textAlign="center">
                Totales
            </Text>
            {/* Verificar si hay resultados antes de mostrar la tabla */}            
            {stats.length === 0 ? (
                <Text fontSize="md" textAlign="center">
                No hay resultados para mostrar.
                </Text>
            ) : (
                <>
                    <TableContainer mt={4}>
                        <Table variant="simple">
                            <Thead>
                            {/* Primera fila de encabezado, que agrupa los tipos de tickets */}
                            <Tr>
                                <Th rowSpan={2}>Ref</Th>
                                {ticketTypes?.map((type) => (
                                <Th key={type} colSpan={2} textAlign="center">
                                    {type}
                                </Th>
                                ))}
                            </Tr>
                            {/* Segunda fila de encabezado, con las subcolumnas Total y Free */}
                            <Tr>
                                {ticketTypes?.map((type) => (
                                <React.Fragment key={type}>
                                    <Th>Total</Th>
                                    <Th>Free</Th>
                                </React.Fragment>
                                ))}
                            </Tr>
                            </Thead>
                            <Tbody>
                            {stats?.map((referrerStats) => (
                                <Tr key={referrerStats.id}>
                                <Td>
                                    <Text fontWeight="bold">
                                    {referrerStats.name} {referrerStats.surnames}
                                    </Text>
                                </Td>
                                {ticketTypes?.map((type) => (
                                    <React.Fragment key={type}>
                                    <Td>
                                        {referrerStats.types[type]
                                        ? `${referrerStats.types[type].totalCheckedIn} / ${referrerStats.types[type].totalTickets}`
                                        : '0 / 0'}
                                    </Td>
                                    <Td>
                                        {referrerStats.types[type]
                                        ? `${referrerStats.types[type].totalFreeAndCheckedIn} / ${referrerStats.types[type].totalFree}`
                                        : '0 / 0'}
                                    </Td>
                                    </React.Fragment>
                                ))}
                                </Tr>
                            ))}
                            {/* Fila de totales */}
                            {
                                currentUser && (currentUser.role == 'admin' || currentUser.role == 'masteruser' || utilHandlers.doesUserCan(currentUser?.user_can, 'seecount', 'venue', currentEvent?.venue?.id)) && <>
                                    <Tr fontWeight="bold" bg="whiteScheme.300">
                                        <Td textAlign="right">TOTAL</Td>
                                        {ticketTypes?.map((type) => (
                                            <React.Fragment key={type}>
                                            <Td>{totals.totalCheckedIn} / {totals.totalTickets}</Td>
                                            <Td>{totals.totalFreeCheckedIn} / {totals.totalFree}</Td>
                                            </React.Fragment>
                                        ))}
                                    </Tr>
                                </>
                            }  
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    </>
  );
}

export default TicketStatsTable;