import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import LoginForm from '../Components/LoginForm';
import RegisterForm from '../Components/RegisterForm';

import { useRecoilState } from 'recoil';

import { userState } from '../Recoil/atoms';
import CreateUserForm from '../Components/CreateUserForm';
import UploadForm from '../Components/UploadForm';
import CreateEventForm from '../Components/CreateEventForm';

const CreateEventPage = () => {

  const [whatToDo, setWhatToDo] = useState('login');

  const [user, setUser] = useRecoilState(userState);

  return (
    <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      {
          user && (user.role == 'masteruser' || user.role == 'admin') ? <>
            <CreateEventForm />
          </> : <></>
      }
    </Box>
  );
};

export default CreateEventPage;