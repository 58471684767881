import React from 'react';
import { Button } from '@chakra-ui/react';

const LoadMoreButton = ({ isLoading, allEventsLoaded, onLoadMore }) => {
    if (allEventsLoaded) return null;

    return (
        <Button
            mt="10px"
            onClick={onLoadMore}
            isLoading={isLoading}
            colorScheme="blue"
            borderRadius="full"
        >
            Ver más
        </Button>
    );
};

export default LoadMoreButton;