import React from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';

function LoadingScreen() {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      height="100vh"
      width="100vw"
      backgroundColor="whiteScheme.100"
      position="fixed"
      zIndex="9999"
    >
      <Spinner size="xl" color="blackScheme.500" />
    </Flex>
  );
}

export default LoadingScreen;