import React from 'react';
import dateHandler from '../../Handlers/dateHandler';

const FormattedDate = ({ date }) => {
  const weekDay = dateHandler.toEsDate(date, 'weekDay');
  const day = dateHandler.toEsDate(date, 'day');
  const month = dateHandler.toEsDate(date, 'month');
  const year = dateHandler.toEsDate(date, 'year');

  return (
    <span>
      {weekDay} <strong>{day}</strong> {month}. {year}
    </span>
  );
};

export default FormattedDate;