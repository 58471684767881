import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import userHandler from '../Handlers/userHandler';

const { persistAtom } = recoilPersist();

export const brandState = atom({
    key: 'brandState',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const userState = selector({
    key: 'preUserState',
    get: ({ get }) => {
        return get(preUserState);
    },
    set: ({ set }, newValue) => {
        if(newValue) {
            const processedValue = userHandler.getReady(newValue);
            set(preUserState, processedValue);
        } else {
            set(preUserState, newValue);
        }
    }
});

export const preUserState = atom({
    key: 'userState',
    default: JSON.parse(localStorage.getItem('user')) || null,
    effects_UNSTABLE: [persistAtom]
});

export const appState = atom({
    key: 'isLoading',
    default: false,
    effects_UNSTABLE: [persistAtom]
});

export const eventsState = atom({
    key: 'currentEvents',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const albumsState = atom({
    key: 'currentAlbums',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const albumState = atom({
    key: 'currentAlbum',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const eventState = atom({
    key: 'currentEvent',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const pageState = atom({
    key: 'currentPage',
    default: '/',
    effects_UNSTABLE: [persistAtom]
});

export const ticketState = atom({
    key: 'currentTicket',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const refState = atom({
    key: 'currentRef',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const ticketsState = atom({
    key: 'currentTickets',
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export const messageState = atom({
    key: 'currentMessage',
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export const userToEditState = selector({
    key: 'preUserToEditState',
    get: ({ get }) => {
        return get(preUserToEditState);
    },
    set: ({ set }, newValue) => {
        if(newValue) {
            const processedValue = userHandler.getReady(newValue);
            set(preUserToEditState, processedValue);
        } else {
            set(preUserToEditState, newValue);
        }
    }
});

export const preUserToEditState = atom({
    key: 'userToEdit',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const eventToEditState = atom({
    key: 'eventToEdit',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const venueToEditState = atom({
    key: 'venuetToEdit',
    default: null,
    effects_UNSTABLE: [persistAtom]
});