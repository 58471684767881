import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import {
  Button,
  Text,
  Image,
  Tag,
  Input,
  FormControl,
  Box,
  IconButton,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  NumberInput,
  NumberInputField,
  FormLabel,
  Switch,
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverContent,
  PopoverBody,
  TagLeftIcon,
  InputGroup,
  InputRightElement,
  Flex,
  Badge
} from '@chakra-ui/react';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState, eventState, appState, ticketState, refState, ticketsState, brandState } from '../Recoil/atoms';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import { PiTShirt } from 'react-icons/pi';
import { MdLocationOn } from "react-icons/md";
import { LuScanLine } from "react-icons/lu";
import { RxUpdate } from "react-icons/rx";
import LoginPage from './LoginPage';
import apiHandler from '../Handlers/apiHandler';
import dateHandler from '../Handlers/dateHandler';
import utilHandlers from '../Handlers/utilHandlers';
import QrScannerComponent from '../Components/QrScannerComponent';

import TicketActions from '../Components/TicketActions';

import { useMetaPixel } from '../Pixels/useMetaPixel';

import { AuthContext } from '../context/AuthContext';

import { useDebounce } from 'use-debounce';

function TicketsPage({ customButton }) {

  const { logout } = useContext(AuthContext);
  
  const { eventId } = useParams();
  const baseURL = 'https://api.myguestlists.com';

  const [event, setEvent] = useRecoilState(eventState);
  const [user, setUser] = useRecoilState(userState);
  const [isLoading, setLoading] = useRecoilState(appState);
  const [ticket, setTicket] = useRecoilState(ticketState);
  const [ref, setRef] = useRecoilState(refState);
  const [tickets, setTickets] = useRecoilState(ticketsState || []);
  const [doReload, setDoReload] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [isQrLoaded, setQrLoaded] = useState(false);
  const [nouserName, setNouserName] = useState('');
  const [qty, setQty] = useState(1);
  const [isFree, setIsFree] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [openQr, setOpenQr] = useState(false);
  const [brandData, setBrandData] = useRecoilState(brandState);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const trackEvent = useMetaPixel(brandData?.result?.fb_pixel_id);

  const navigate = useNavigate();

  const createTicket = async (user, free = false) => {
    const body = {
      name: 'Guest List',
      description: event?.guest_list_info,
      type: 'discount',
      free,
      checked_in: false,
      eventId: event?.id,
      userId: user.id,
      refId: ref ?? user.refId ?? null,
      venueId: brandData?.type == 'venue' ? brandData?.result?.id : event?.venue.id,
      promoterId: brandData?.type == 'promoter' ? brandData?.result?.id : event?.promoters[0].id,
    };
    setLoading(true);
    try {
      if(user) {
        const ticket = await apiHandler.createTicket(body, logout);
        setTicket(ticket.ticket);
        trackEvent('GuestList', {
          email: user?.email,
          phone: user?.phone,
          first_name: user?.name,
          last_name: user?.surnames,
          url: window.location.href,
          refId: ref,
          client_user_agent: navigator.userAgent,
          event_name: event?.name,
          event_venue: event?.venue?.name
        });
        setDoReload(true);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const createCustomTicket = async () => {
    const venueId = event?.venue?.id;
    const freePermission = user.role === 'masteruser' || user.role === 'admin' || await utilHandlers.doesUserCan(user.user_can, 'setfree', 'venue', venueId);

    // Contar los tickets free que ya tiene este usuario en este evento
    const userFreeTicketsCount = tickets?.filter(
      (ticket) => ticket.free && (ticket.refId == user.id)
    ).length;

    if (isFree && freePermission && userFreeTicketsCount >= await utilHandlers.doesUserCan(user.user_can, 'setfree', 'venue', venueId)) {
      toast({
        title: "No tienes disponibles más accesos Free para este evento",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      for (let i = 0; i < qty; i++) {
        const body = {
          name: 'Guest List',
          description: event?.guest_list_info,
          type: 'discount',
          free: isFree,
          checked_in: false,
          eventId: event?.id,
          userId: null,
          refId: user?.id,
          nouser_name: `${nouserName} (${i + 1})`,
          lastEditFree: isFree ? user?.id : null,
          venueId: brandData?.type == 'venue' ? brandData?.result?.id : event?.venue?.id,
          promoterId: brandData?.type == 'promoter' ? brandData?.result?.id : event?.promoters[0]?.id,
        };
        if(user) {
          const newTicket = await apiHandler.createCustomTicket(body, logout);
        }
      }
      setDoReload(true);
      onClose();
      setNouserName('');
      setQty(1);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const setFree = async (ticketId) => {
    setLoading(true);
    if(user) {
      try {
        const freeSet = await apiHandler.setFreeTicket(ticketId, logout);
        if(freeSet.error) {
          alert(freeSet.error);
        }
        setDoReload(true);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(false);
  };

  const checkInTicket = async (ticketId) => {
    if(user){
      setLoading(true);
      try {
        await apiHandler.checkinTicket(ticketId, logout);
        setDoReload(true);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };

  const deleteTicket = async (ticketId) => {
    if(user) {
      setLoading(true);
      try {
        await apiHandler.deleteTicket(ticketId, logout);
        setDoReload(true);
      } catch (error) {
        console.error(error);
      }
      onDeleteClose();
      setLoading(false);
    }
  };

  const fetchCurrentTickets = async (eventId) => {
    if(user) {
      setLoading(true);
      try {
        const ticketsRes = await apiHandler.getTicketsByEvent(eventId, logout);
        if (!ticketsRes.error) {
          const sortedTickets = ticketsRes.sort((a, b) => {
            const nameA = a.user?.name
              ? (a.user.name + ' ' + a.user.surnames).toLowerCase()
              : a.nouser_name?.toLowerCase() || '';
            const nameB = b.user?.name
              ? (b.user.name + ' ' + b.user.surnames).toLowerCase()
              : b.nouser_name?.toLowerCase() || '';
            return nameA.localeCompare(nameB);
          });
          setTickets(sortedTickets);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };

  const fetchTicket = async (user, event) => {
    try {
      setLoading(true);
      const ticket = await apiHandler.getTicketByUserEvent(event?.id, user?.id, logout);
      setTicket(ticket);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setTicket(null);
      setLoading(false);
    }
  };

  const fetchCurrentEvent = async (eventId) => {
    try {
      setLoading(true);
      const event = await apiHandler.getPublicEvent(eventId, logout);
      setEvent(event);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getFormattedDate = (event) => {
    const weekDay = dateHandler.toEsDate(event?.start_date_time, 'weekDay');
    const day = dateHandler.toEsDate(event?.start_date_time, 'day');
    const month = dateHandler.toEsDate(event?.start_date_time, 'month');
    const year = dateHandler.toEsDate(event?.start_date_time, 'year');

    const FormattedDate = () => (
      <span>
        {weekDay} <strong>{day}</strong> {month}. {year}
      </span>
    );

    return FormattedDate();
  };

  useEffect(() => {
    setTickets([]);
    if(user && user.role !== 'user') {
      fetchCurrentTickets(eventId);
    }
    // Ejecuta la función inmediatamente cuando el componente se monta
  }, [eventId]);

  useEffect(() => {
    setTickets([]);
    fetchCurrentEvent(eventId);
    if (event && user && user.role === 'user') {
      fetchTicket(user, event);
    }
    if (event && user && user.role !== 'user') {
      fetchCurrentTickets(event?.id);
    }

  }, []);

  useEffect(() => {
    setTickets([]);
    if (event && user && user.role === 'user') {
      fetchTicket(user, event);
    }
    if (event && user && user.role !== 'user') {
      fetchCurrentTickets(event?.id);
    }
  }, [user, event]);

  useEffect(() => {
    setTickets([]);
    if (event && user && user.role !== 'user') {
      fetchCurrentTickets(event?.id);
    }
    setDoReload(false);
  }, [doReload]);

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const handleSearch = (event) => {
    const { value } = event?.target;
    setSearchString(normalizeText(value));
  };


  const filteredTickets = useMemo(() => {
    return tickets.filter((ticket) => {
      const fullName = ticket?.user
        ? `${ticket.user.name} ${ticket.user.surnames}`
        : ticket.nouser_name || '';
  
      const normalizedFullName = normalizeText(fullName);
      const searchTerms = searchString.split(' ')?.filter(term => term);
  
      return searchTerms.every(term =>
        normalizedFullName.includes(term) ||
        (ticket.free && 'free'.includes(term))
      );
    });
  }, [searchString, tickets]);

  const downloadPDF = async () => {
    const pdfUrl = `https://api.myguestlists.com/public/pdfs/ticket-${ticket.id}.pdf`;

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `ticket-${ticket.id}.pdf`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const rolesListado = ['masteruser', 'admin', 'rrpp', 'comm'];


  return (
    <>
      <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" key={event?.id}>
        <Box display="flex" gap="10px">
          <IconButton
            size="md"
            icon={<ArrowBackIcon />}
            colorScheme="blackScheme"
            variant="outline"
            onClick={() => navigate(`/event/${event?.id}`)}
          />
          <Box display="flex" flexDirection="column" gap="0px" alignItems="start">
            <Text fontSize="lg" fontWeight="600">
              {event?.name} <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="2xs">
                <TagLeftIcon as={MdLocationOn} />
                  {event?.venue?.name}
                </Tag>
            </Text>
            <Text fontSize="xs">{getFormattedDate(event)}</Text>
          </Box>
          {
            user && event && rolesListado.includes(user.role) && <>
              <IconButton
                size="md"
                icon={<RxUpdate />}
                colorScheme="blackScheme"
                onClick={() => fetchCurrentTickets(event?.id)}
                marginLeft="auto"
              />
            </>
          }
        </Box>
        { user && event ? (
          rolesListado.includes(user.role) ? (
            <>
              {
                (user.role == 'masteruser' || user.role == 'admin' || (user.user_can && utilHandlers.doesUserCan(user.user_can, 'seecount', 'venue', event?.venue?.id))) && tickets &&
                <Tag fontSize="xs" justifyContent="center" bg="whiteScheme.300" display='flex' gap="20px">
                  <span><b>Total: {tickets?.filter((ticket) => ticket.checked_in).length}</b>/{tickets?.length}</span>
                  <span><b>Free: {tickets?.filter((ticket) => ticket.free && ticket.checked_in).length}</b>/{tickets?.filter((ticket) => ticket.free).length}</span>
                  <span><b>Filter: {filteredTickets?.filter((ticket) => ticket.checked_in).length}</b>/{filteredTickets?.length}</span>
                </Tag>
              }
              <FormControl id="search" width="100%">
                <InputGroup>
                  <Input
                    type="text"
                    name="search"
                    value={searchString}
                    onChange={handleSearch}
                    borderRadius="full"
                    placeholder="Buscar..."
                  />
                  {searchString && (
                    <InputRightElement>
                      <Button
                        size="sm"
                        onClick={() => setSearchString('')}  // Esto limpia el campo de búsqueda
                        borderRadius="full"
                        variant="ghost"
                        colorScheme='whiteScheme'
                        aria-label="Clear search"
                      >
                        <CloseIcon />
                      </Button>
                    </InputRightElement>
                  )}
                </InputGroup>
              </FormControl>
              {
                (user.role == 'masteruser' || user.role == 'admin' || (user.user_can && utilHandlers.doesUserCan(user.user_can, 'setfree', 'venue', event?.venue?.id))) && (
                  <Button borderRadius="full" onClick={onOpen} colorScheme="blackScheme" marginBottom="10px">
                    Añadir a lista
                  </Button>
                )
              }

              <TableContainer overflowY="auto">
                <Table variant="simple">
                  <Tbody>
                    {filteredTickets &&
                      filteredTickets?.map((ticket) => {
                        const color = ticket.checked_in ? 'whiteScheme.600' : 'blackScheme.400';
                        return (
                          <Popover key={ticket.id}>
                            <PopoverTrigger>
                              <Tr height={60.5} color={color}>
                                <Td padding="10px 0px" >
                                  <Flex gap="10px">
                                    <Text
                                      fontSize="md"
                                      display="inline"
                                      cursor="pointer"
                                    >
                                      {ticket.user
                                        ? `${ticket?.user?.name} ${ticket?.user?.surnames}`
                                        : ticket.nouser_name}
                                    </Text>
                                    {ticket.free && (
                                      <Tag bgColor="whiteScheme.400" color={color} fontSize="2xs">
                                        Free
                                      </Tag>
                                    )}
                                  </Flex>
                                  <Flex gap="10px">
                                    {ticket.referrer && (
                                      <Text fontSize="xs" display="inline">
                                        {ticket.referrer.name} {ticket.referrer.surnames}
                                      </Text>
                                    )}
                                    {(user?.role == 'admin' || user?.role == 'masteruser') && ticket.promoter && (
                                      <Badge bgColor="whiteScheme.300" color={color} fontSize="2xs">
                                        {ticket?.promoter?.name}
                                      </Badge>
                                    )}
                                  </Flex>
                                </Td>
                                <Td textAlign="end" padding="10px 0px">
                                  {/* Aquí puedes agregar más contenido si es necesario */}
                                </Td>
                              </Tr>
                            </PopoverTrigger>
                            {(user.role === 'masteruser' || user.role === 'admin' || utilHandlers.doesUserCan(user.user_can, 'setfree', 'venue', event?.venue.id) || utilHandlers.doesUserCan(user?.user_can, 'checkin', 'venue', event?.venue?.id)) && (
                              <PopoverContent borderRadius="full">
                                <PopoverArrow />
                                <PopoverBody style={{outline: 'none'}}>
                                <TicketActions
                                  ticket={ticket}
                                  user={user}
                                  event={event}
                                  deleteTicket={deleteTicket}
                                  checkInTicket={checkInTicket}
                                  setFree={setFree}
                                  showDeleteModal={true}  // Mostrar modal de confirmación para eliminar
                                  showCheckInModal={false} // Mostrar modal de confirmación para check-in/out
                                  showSetFreeModal={true} // No mostrar modal de confirmación para set free/unset free
                                />
                                </PopoverBody>
                              </PopoverContent>
                            )}
                          </Popover>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Añadir a la lista</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl>
                      <FormLabel>Nombre</FormLabel>
                      <Input
                        id="nouser_name"
                        value={nouserName}
                        onChange={(e) => setNouserName(e.target.value)}
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Cantidad</FormLabel>
                      <NumberInput min={1} value={qty} onChange={(value) => setQty(parseInt(value, 10))}>
                        <NumberInputField id="qty" />
                      </NumberInput>
                    </FormControl>
                    {(user.role === 'masteruser' || user.role === 'admin' || utilHandlers.doesUserCan(user.user_can, 'setfree', 'venue', event?.venue?.id)) && (
                      <FormControl mt={4} display="flex" alignItems="center">
                        <FormLabel htmlFor="isFree" mb="0">
                          Free
                        </FormLabel>
                        <Switch
                          id="isFree"
                          isChecked={isFree}
                          onChange={() => setIsFree(!isFree)}
                        />
                      </FormControl>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={createCustomTicket} colorScheme="blackScheme">
                      Apuntar en lista
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              {
                (user.role == 'masteruser' || user.role == 'admin' || utilHandlers.doesUserCan(user.user_can, 'checkin', 'venue', event?.venue?.id)) && <>
                  <IconButton
                    size="lg"
                    icon={<LuScanLine />}
                    colorScheme="blackScheme"
                    onClick={() => {
                      setOpenQr(true)}
                    }
                    position="fixed"
                    right="20px"
                    bottom="20px"
                    zIndex="999"
                  />
                  {
                    openQr && <QrScannerComponent onClose={setOpenQr} onCheckIn={checkInTicket} onDelete={deleteTicket} onSetFree={setFree} user={user} event={event} />
                  }
                </>
              }
            </>
          ) : ticket && ticket.id ? (
            <>
              <div id="qrticket" style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Text textAlign="center">
                  Muestra este QR en la puerta para {ticket.type === 'discount' ? 'obtener tu descuento' : 'validar tu entrada'}
                </Text>
                <Image
                  id="qrimage"
                  src={`${baseURL}/qrs/ticket-${ticket.id}.png`}
                  width="100%"
                  onLoad={() => setQrLoaded(true)}
                />
                <Text textAlign="center" fontWeight="600" fontSize="md">
                  {event?.name}
                </Text>
                {
                  ticket?.free ? <>
                      <Tag bgColor="whiteScheme.400" color='blackScheme.400' fontSize="2xs">
                          Free Access
                      </Tag>
                      <Text whiteSpace="pre-wrap" textAlign="center" fontSize="xs" marginTop="20px">Acceso gratuito hasta las {dateHandler.toTime(event?.venue?.free_until)}h</Text>
                  </> : <>
                      <Text textAlign="center" fontSize="sm">{ticket?.name}</Text>
                      <Text whiteSpace="pre-wrap" textAlign="center" fontSize="xs">{ticket?.description}</Text>
                  </>
                }
              </div>
              <Button borderRadius="full" colorScheme="blackScheme" isDisabled={!isQrLoaded} onClick={() => downloadPDF()}>
                Descargar QR
              </Button>
            </>
          ) : (
            <>
              <Box padding="10px">
                <Text whiteSpace="pre-wrap" fontSize="sm">
                  {event?.guest_list_info}
                </Text>
              </Box>
              {
                event?.guest_list_closes && event?.guest_list_closes < dateHandler.nowUTCDate() ? /*dateHandler.nowUTCDate() < event?.end_date_time && dateHandler.nowUTCTime() < event?.venue?.list_closes ?*/ <>
                  <Button borderRadius="full" bg="blackScheme.300" _hover={{bg:'blackScheme.300'}}>
                    Lista cerrada
                  </Button>
                </> : <>
                  <Button borderRadius="full" onClick={() => createTicket(user)}>
                    Apuntarme por lista
                  </Button>
                  
                </>
              }
            </>
          )
        ) : (
          <LoginPage />
        )}
      </Box>
    </>
  );
}

export default TicketsPage;