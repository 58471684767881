import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { brandState, appState, albumsState } from '../Recoil/atoms';
import { Box, Text } from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';
import apiHandler from '../Handlers/apiHandler';

import AlbumCard from './AlbumsPage/AlbumCard';
import LoadMoreButton from './AlbumsPage/LoadMoreButton';

const Albums = () => {
  const { logout } = useContext(AuthContext);
  const [brandData] = useRecoilState(brandState);
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentAlbums, setCurrentAlbums] = useRecoilState(albumsState);

  const [updating, setUpdating] = useState(false);
  const [allAlbumsLoaded, setAllAlbumsLoaded] = useState(false);

  const pageRef = useRef(0);
  const navigate = useNavigate();

  const resetAlbums = () => {
    setAllAlbumsLoaded(false);
    setCurrentAlbums([]);
    pageRef.current = 0;
  };

  const fetchAlbums = async () => {
    setUpdating(true);
    setLoading(true);

    try {
      const albums = await apiHandler.getPublicAlbums(
        brandData?.type,
        brandData?.result.id,
        pageRef.current,
        logout
      );

      if (albums?.length) {
        setCurrentAlbums((prev) => [...prev, ...albums]);
        pageRef.current += 1;
        if (albums.length < 5) setAllAlbumsLoaded(true);
      } else {
        setAllAlbumsLoaded(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    resetAlbums();
    fetchAlbums();
  }, [brandData]);

  return (
    <Box
      padding="10px"
      w="100%"
      display="flex"
      flexDir="column"
      gap="20px"
      overflow="hidden"
      borderColor="whiteScheme.300"
      borderWidth="2px"
      borderRadius="20px"
      bgColor="whiteScheme.100"
      alignItems="center"
    >
      {currentAlbums?.length > 0 ? (
        currentAlbums.map((album) => (
          <AlbumCard
            key={album.id}
            album={album}
            onClick={() => navigate(`/album/${album.id}`)}
          />
        ))
      ) : (
        <Text>Todavía no hay álbums disponibles.</Text>
      )}
      {!allAlbumsLoaded && currentAlbums?.length > 0 && (
        <LoadMoreButton
          isLoading={updating}
          allAlbumsLoaded={allAlbumsLoaded}
          onLoadMore={fetchAlbums}
        />
      )}
    </Box>
  );
};

export default Albums;