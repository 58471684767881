import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useRecoilState } from 'recoil';

import { appState, userState, pageState, refState, messageState, brandState } from '../Recoil/atoms';
import apiHandler from '../Handlers/apiHandler';

import { useMetaPixel } from '../Pixels/useMetaPixel';

import { AuthContext } from '../context/AuthContext';

const RegisterForm = ({ doAfterLogin }) => {

  const { logout } = useContext(AuthContext);
  
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [currentRef, setCurrentRef] = useRecoilState(refState);
  const [brandData, setBrandData] = useRecoilState(brandState);

  const trackEvent = useMetaPixel(brandData?.result?.fb_pixel_id);

  const [formData, setFormData] = useState({
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    name: '',
    surnames: '',
    phone: '',
    birthdate: '',
    location: '',
    refId: currentRef ? currentRef : null,
    venueId: null,
    promoterId: null,
    eventId: null,
    role: 'user',
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [currentMessage, setCurrentMessage] = useRecoilState(messageState);


  // Manejador de cambios para todos los campos
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      'email', 'confirmEmail', 'password', 'confirmPassword', 'name',
      'surnames', 'phone', 'birthdate', 'location'
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        alert(`El campo ${field} es obligatorio`);
        return;
      }
    }

    // Validar email y confirmEmail
    if (formData.email !== formData.confirmEmail) {
      setEmailError('Los correos electrónicos no coinciden');
      return;
    } else {
      setEmailError('');
    }

    // Validar password y confirmPassword
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Las contraseñas no coinciden');
      return;
    } else {
      setPasswordError('');
    }

    const newUser = await createUser(formData);


  };

  const createUser = async (formData) => {
    try {
      setLoading(true);
      const user = await apiHandler.createUser(formData, logout);
      if (user.error) {
        trackEvent('FailedUserRegistration', {
          email: formData?.email,
          phone: formData?.phone,
          first_name: formData?.name,
          last_name: formData?.surnames,
          url: window.location.href,
          refId: formData?.refId,
          client_user_agent: navigator.userAgent,
          error: user.error
        }, true);
        setLoading(false);
        alert(user.error);
        return user.error;
      }
      
      trackEvent('CompleteRegistration', {
        email: formData?.email,
        phone: formData?.phone,
        first_name: formData?.name,
        last_name: formData?.surnames,
        url: window.location.href,
        refId: formData?.refId,
        client_user_agent: navigator.userAgent
      });
  
      trackEvent('UserRegistration', {
        email: formData?.email,
        phone: formData?.phone,
        first_name: formData?.name,
        last_name: formData?.surnames,
        url: window.location.href,
        refId: formData?.refId,
        client_user_agent: navigator.userAgent
      }, true);

      setRegistrationSuccess(true); // Cambiar el estado si el registro es exitoso
      setCurrentMessage(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Box
      p="10px"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
    >
      {registrationSuccess ? (
        <Text fontSize="xl" fontWeight="600" marginTop="20px" textAlign="center">
          Usuario registrado correctamente. Haz click en el enlace que te hemos enviado al email para verificar y activar tu cuenta. Revisa también la carpeta de SPAM.
        </Text>
      ) : (
        <>
          <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">Registro</Text>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4} marginBottom="20px">
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="confirmEmail" isRequired>
                <FormLabel>Confirmar Email</FormLabel>
                <Input
                  type="email"
                  name="confirmEmail"
                  value={formData.confirmEmail}
                  onChange={handleChange}
                />
                {emailError && <Text color="red.500">{emailError}</Text>}
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel>Contraseña</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="confirmPassword" isRequired>
                <FormLabel>Confirmar Contraseña</FormLabel>
                <Input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                {passwordError && <Text color="red.500">{passwordError}</Text>}
              </FormControl>

              <FormControl id="name" isRequired>
                <FormLabel>Nombre</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="surnames">
                <FormLabel>Apellidos</FormLabel>
                <Input
                  type="text"
                  name="surnames"
                  value={formData.surnames}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="phone">
                <FormLabel>Teléfono</FormLabel>
                <Input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="birthdate" isRequired>
                <FormLabel>Fecha de Nacimiento</FormLabel>
                <Input
                  type="date"
                  name="birthdate"
                  value={formData.birthdate}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl id="location">
                <FormLabel>Localidad</FormLabel>
                <Input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </FormControl>

              <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme'>
                Registrarme
              </Button>
            </Stack>
          </form>
        </>
      )}
    </Box>
  );
};

export default RegisterForm;