import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { appState, userState } from '../Recoil/atoms';

import apiHandler from '../Handlers/apiHandler';

import { AuthContext } from '../context/AuthContext';

const LoginForm = ({ doAfterLogin }) => {

  const { logout } = useContext(AuthContext);

  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser, setCurrentUser] = useRecoilState(userState);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [formError, setFormError] = useState('');

  // Manejador de cambios para todos los campos
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validar que los campos no estén vacíos
    if (!formData.email || !formData.password) {
      setFormError('Ambos campos son obligatorios');
      return;
    } else {
      setFormError('');
    }

    try {
      setLoading(true);
      const user = await apiHandler.doLogin(formData.email, formData.password, logout);
      if (user.error) {
        setFormError(user.error);
        setLoading(false);
        return;
      }
      await setCurrentUser(user);
      setLoading(false);
      if (doAfterLogin) doAfterLogin(); // Acción opcional después de iniciar sesión
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setLoading(false);
      setFormError('Hubo un problema al iniciar sesión');
    }
  };

  return (
    <Box
      p="10px"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
    >
      <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">Iniciar sesión</Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="20px">
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="password">
            <FormLabel>Contraseña</FormLabel>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </FormControl>

          {formError && <Text color="red.500">{formError}</Text>}

          <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme' isLoading={isLoading}>
            Iniciar sesión
          </Button>
        </Stack>
      </form>
      <Text fontSize="sm"><span onClick={() => navigate('/reset-password')} style={{fontWeight: "600", cursor: "pointer"}}>Restaurar contraseña</span></Text>
    </Box>
  );
};

export default LoginForm;