import React, { useEffect } from 'react';
import {
  Box,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';

import { useRecoilState } from 'recoil';

import { userState, userToEditState } from '../Recoil/atoms'; // Importa userToEditState
import EditUserForm from '../Components/EditUserForm'; // Importa el componente EditUserForm

const EditUserPage = () => {
  const [user, setUser] = useRecoilState(userState);
  const [userToEdit, setUserToEdit] = useRecoilState(userToEditState); // Obtener el usuario a editar

  useEffect(() => {
    if (!userToEdit) {
      // Manejar el caso en que no hay usuario seleccionado para editar
      console.error("No user selected for editing");
      // Aquí podrías redirigir a otra página o mostrar un mensaje de error.
    }
  }, [userToEdit]);

  return (
    <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      {user && (user.role !== 'user' && user.role !== 'rrpp' && user.role !== 'comm') ? (
        userToEdit ? (
          <EditUserForm user={userToEdit} /> // Pasa el usuario a editar como prop
        ) : (
          <Text>No hay usuario seleccionado para editar.</Text>
        )
      ) : (
        <Text>No tienes permisos para editar usuarios.</Text>
      )}
    </Box>
  );
};

export default EditUserPage;