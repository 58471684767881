import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import apiHandler from '../Handlers/apiHandler';
import dateHandler from '../Handlers/dateHandler';

import { brandState, appState, eventsState, eventState, ticketState, userState, ticketsState, refState, eventToEditState } from '../Recoil/atoms';

import { Box, Image, Modal, ModalBody, ModalHeader, ModalContent, ModalCloseButton, Card, CardHeader, CardBody, CardFooter, Stack, Button, IconButton, useTheme, Tag, TagLeftIcon, Text, useDisclosure, Flex, Tooltip } from '@chakra-ui/react';
import { AddIcon, ArrowForwardIcon, ArrowBackIcon, EditIcon, CopyIcon } from '@chakra-ui/icons';
import { MdLocationOn, MdOutlineQueryStats } from "react-icons/md";
import { LuScanLine } from "react-icons/lu";
import { PiTShirt } from "react-icons/pi";

import utilHandlers from '../Handlers/utilHandlers';

import { useMetaPixel } from '../Pixels/useMetaPixel';

import { AuthContext } from '../context/AuthContext';

const Event = () => {

    const { logout } = useContext(AuthContext);
    
    const { eventId } = useParams();

    const [brandData, setBrandData] = useRecoilState(brandState);
    const [event, setEvent] = useRecoilState(eventState);
    const [isLoading, setLoading] = useRecoilState(appState);
    const [user, setUser] = useRecoilState(userState);
    const [ticket, setTicket] = useRecoilState(ticketState);
    const [tickets, setTickets] = useRecoilState(ticketsState);
    const [ref, setRef] = useRecoilState(refState);
    const [eventToEdit, setEventToEdit] = useRecoilState(eventToEditState);
    const [currentUser, setCurrentUser] = useRecoilState(userState);

    const trackEvent = useMetaPixel(brandData?.result?.fb_pixel_id);

    useEffect(() => {
        trackEvent('ViewContent', {
            email: currentUser?.email,
            phone: currentUser?.phone,
            first_name: currentUser?.name,
            last_name: currentUser?.surnames,
            url: window.location.href,
            refId: ref,
            client_user_agent: navigator.userAgent,
            event_name: event?.name,
            event_venue: event?.venue?.name
        });

        trackEvent('ViewEvent', {
            email: currentUser?.email,
            phone: currentUser?.phone,
            first_name: currentUser?.name,
            last_name: currentUser?.surnames,
            url: window.location.href,
            refId: ref,
            client_user_agent: navigator.userAgent,
            event_name: event?.name,
            event_venue: event?.venue?.name
        }, true);
    }, []);

    const navigate = useNavigate();

    const fetchTicket = async (user, event) => {
        try {
            setLoading(true);
            const ticket = await apiHandler.getTicketByUserEvent(event.id, user.id, logout);
            await setTicket(ticket);
            setLoading(false);
        } catch (error) {
            console.error(error);
            await setTicket(null);
            setLoading(false);
        }
    }

    const fetchCurrentEvent = async (eventId) => {
        try {
            setLoading(true);
            const event = await apiHandler.getPublicEvent(eventId, logout);
            await setEvent(event);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
        
    }

    const fetchCurrentTickets = async (eventId) => {
        try {
            setLoading(true);
            const ticketsRes = await apiHandler.getTicketsByEvent(eventId, logout);
            if(!ticketsRes.error) {
                await setTickets(ticketsRes);
            }
            setLoading(false);
        }catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        setEvent(null);
        fetchCurrentEvent(eventId);
        if(event && user ) {
            fetchTicket(user, event);
        }
        if(event && user && user.role !== 'client') {
            fetchCurrentTickets(event?.id);
        }
    }, []);

    useEffect(() => {
        if(event && user ) {
            fetchTicket(user, event);
        }
        if(event && user && user.role !== 'client') {
            fetchCurrentTickets(event?.id);
        }
    }, [user, event]);

    const getFormattedDate = (event) => {
        const weekDay = dateHandler.toEsDate(event.start_date_time, 'weekDay');
        const day = dateHandler.toEsDate(event.start_date_time, 'day');
        const month = dateHandler.toEsDate(event.start_date_time, 'month');
        const year = dateHandler.toEsDate(event.start_date_time, 'year');

        const FormattedDate = () => {
            return (
                <span>
                    {weekDay} <strong>{day}</strong> {month}. {year}
                </span>
            );
        }

        return FormattedDate();
    }

    const handleEditEvent = () => {
        setEventToEdit(event); // Establecer el evento actual en el estado global
        navigate(`/edit-event`); // Navegar a la página de edición del evento
    }

    const handleDuplicateEvent = () => {
        // Redirigir a la página de creación con los datos del evento para duplicar
        navigate('/create-event', { state: { eventToDuplicate: event } });
      };

    const eventFlyer = event?.flyer;

    return (
        <>
            {
                event ? <>
                    <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" key={event.id}>
                        <Box display="flex" justifyContent="space-between">
                            <Box display="flex" gap="10px">
                                <IconButton size="md" icon={<ArrowBackIcon />} colorScheme="blackScheme" variant='outline' onClick={() => navigate('/events')} />
                                <Box display="flex" flexDirection="column" gap="0px" alignItems="start">
                                    <Text>{getFormattedDate(event)}</Text>
                                    <Text fontSize="xs" color="blackScheme.200">{dateHandler.toTime(event?.start_date_time)} - {dateHandler.toTime(event.end_date_time)}</Text>
                                </Box>
                            </Box>
                            <Flex gap="10px">
                            {
                                (user?.role == 'masteruser' || user?.role == 'admin' || user?.role == 'rrpp' || user?.role == 'comm' ) &&
                                    <Tooltip label="Stats" aria-label="Stats">
                                        <IconButton size="md" icon={<MdOutlineQueryStats />} colorScheme="blue" onClick={() => navigate(`/stats/${event?.id}`)} />
                                    </Tooltip>
                            }
                            {
                                (user?.role == 'masteruser' || user?.role == 'admin') &&
                                    <Tooltip label="Duplicar evento" aria-label="Duplicar evento">
                                        <IconButton size="md" icon={<CopyIcon />} colorScheme="blue" onClick={handleDuplicateEvent} />
                                    </Tooltip>
                            }
                            { 
                                (user?.role == 'masteruser' || user?.role == 'admin') &&
                                    <Tooltip label="Editar evento" aria-label="Editar evento">
                                        <IconButton size="md" icon={<EditIcon />} colorScheme="blue" onClick={handleEditEvent} />
                                    </Tooltip>
                            }
                            </Flex>
                        </Box>
                        <Box w="100%" overflow="hidden" display='flex' key={event?.id} onClick={() => {}}>
                            {
                                (eventFlyer && !utilHandlers.isMp4(eventFlyer)) && <>
                                    <Image src={eventFlyer} maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{aspectRatio: 1080/1350}} borderRadius="9px"/>
                                </>
                            }
                            {
                                (eventFlyer && utilHandlers.isMp4(eventFlyer)) && <>
                                    <Box as="video" autoPlay loop muted playsInline maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{aspectRatio: 1080/1350}} borderRadius="9px">
                                        <source src={eventFlyer} type="video/mp4" />
                                    </Box>
                                </>
                            }
                            <Box padding="0 10px" display="flex" gap="5px" alignItems="start" flexDirection="column">
                                <Box display="flex" flexDir="column" gap="5px">
                                    <Text fontSize="3xl" fontWeight="600" lineHeight="1.1em">{event?.name}</Text>
                                    <Box display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                                        <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="2xs">
                                            <TagLeftIcon as={MdLocationOn} />
                                            {event?.venue?.name}
                                        </Tag>
                                        <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="2xs">
                                            {"+" + event.minimumage}
                                        </Tag>
                                        <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="2xs">
                                            <TagLeftIcon as={PiTShirt} />
                                            {event.dresscode}
                                        </Tag>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Text whiteSpace="pre-wrap" fontSize="s" color="blackScheme.200">{event.description}</Text>      
                        </Box>
                        {

                        }
                        <Box display="flex" flexDir="column" gap="10px">
                            <Text fontSize="2xl" textTransform="uppercase" fontWeight="600">Tickets</Text>
                            <Box padding="0" borderColor="whiteScheme.400" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.300" display="flex" flexDir="column">
                                <Box padding="10px" display="flex" alignItems="center" justifyContent="space-between" borderWidth={event?.guest_list_info?.trim() ? "0 0 2px 0" : '0'} borderColor="whiteScheme.400">
                                    {
                                        event.tickets_link && (currentUser?.role == 'user' || !currentUser) ? <>
                                            <Text fontSize="lg" fontWeight="500">Entradas anticipadas</Text>
                                            {
                                                !event.sold_out ? <>
                                                    <IconButton size="md" icon={<ArrowForwardIcon />}colorScheme="blackScheme" onClick={() => { window.open(`${event.tickets_link}${(ref ? '?coupon-code=' + ref : '')}`) }}/>
                                                </> : <>
                                                    Sold Out
                                                </>
                                            }
                                        </> : <>
                                            <Text fontSize="lg" fontWeight="500">Guest List</Text>
                                            <IconButton size="md" icon={<ArrowForwardIcon />}colorScheme="blackScheme" onClick={() => {navigate(`/tickets/${event.id}`)}}/>
                                        </>
                                    }
                                </Box>
                                {
                                    event?.guest_list_info?.trim() && <>
                                        <Box padding="10px">
                                            <Text whiteSpace="pre-wrap" fontSize="sm">{event.guest_list_info}</Text>
                                        </Box>
                                    </>
                                }
                                
                            </Box>
                        </Box>
                    </Box>
                </> : <></>
            }
        </>
    );
}

export default Event;