import React, { useState } from 'react';
import {
  Box,
  useColorModeValue,
  Text
} from '@chakra-ui/react';

import { useRecoilState } from 'recoil';

import { userState } from '../Recoil/atoms';
import CreateVenueForm from '../Components/CreateVenueForm'; // Importa el formulario de creación de Venue

const CreateVenuePage = () => {

  const [user, setUser] = useRecoilState(userState);

  return (
    <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      {
          user && (user.role === 'masteruser' || user.role === 'admin') ? (
            <CreateVenueForm />
          ) : (
            <Text>No tienes permisos para crear un Venue.</Text>
          )
      }
    </Box>
  );
};

export default CreateVenuePage;