import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import apiHandler from '../Handlers/apiHandler';
import dateHandler from '../Handlers/dateHandler';

import { brandState, appState, eventsState, eventState } from '../Recoil/atoms';

import { Box, Image, Card, CardHeader, CardBody, CardFooter, Stack, Button, useTheme, Tag, Badge, Text } from '@chakra-ui/react';

import utilHandlers from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

const MyTickets = () => {

    const { logout } = useContext(AuthContext);
    
    const [brandData, setBrandData] = useRecoilState(brandState);
    const [isLoading, setLoading] = useRecoilState(appState);
    const [currentEvents, setCurrentEvents] = useRecoilState(eventsState);
    const [event, setEvent] = useRecoilState(eventState);
    const [myTickets, setMyTickets] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchMyTickets();
    }, [brandData]);

    useEffect(() => {
        fetchMyTickets();
    }, []);

    const fetchMyTickets = async () => {
        try {
            setLoading(true);
            const tickets = await apiHandler.getMyTickets(logout);
            const sortedTickets = tickets.sort((a, b) => new Date(a.event.start_date_time) - new Date(b.event.start_date_time));
            await setMyTickets(sortedTickets);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    return (
        <>
            {
                myTickets ? myTickets?.map(ticket => {
                    const event = ticket.event;
                    const weekDay = dateHandler.toEsDate(event.start_date_time, 'weekDay');
                    const day = dateHandler.toEsDate(event.start_date_time, 'day');
                    const month = dateHandler.toEsDate(event.start_date_time, 'month');
                    const year = dateHandler.toEsDate(event.start_date_time, 'year');

                    const FormattedDate = () => {
                        return (
                            <span>
                                {weekDay} <strong>{day}</strong> {month}. {year}
                            </span>
                        );
                    }

                    const eventFlyer = event?.flyer;

                    return (
                        <Box position="relative" w="100%" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" display='flex' key={event.id} onClick={() => {navigate(`/ticket/${ticket.id}`)}}>
                            {
                                (eventFlyer && !utilHandlers.isMp4(eventFlyer)) && <>
                                    <Image src={eventFlyer} maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{aspectRatio: 1080/1350}} borderRadius="9px"/>
                                </>
                            }
                            {
                                (eventFlyer && utilHandlers.isMp4(eventFlyer)) && <>
                                    <Box as="video" autoPlay loop muted playsInline maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{aspectRatio: 1080/1350}} borderRadius="9px">
                                        <source src={eventFlyer} type="video/mp4" />
                                    </Box>
                                </>
                            }
                            <Box padding="10px" display="flex" gap="5px" alignItems="start" flexDirection="column">
                                <Box display="flex" gap="10px" alignItems="center">
                                    <Tag bgColor="blackScheme.300" color="whiteScheme.300" fontSize="xs"><FormattedDate/></Tag>
                                    <Text fontSize="xs">{dateHandler.toTime(event.start_date_time)} - {dateHandler.toTime(event.end_date_time)}</Text>
                                </Box>
                                <Box display="flex" flexDirection="column" gap="10px">
                                    <Text fontSize="2xl" fontWeight="600">{event.name}</Text>
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="xs">{"+" + event.minimumage}</Tag>
                                        <Text fontSize="xs">{event.venue.name}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    );
                }) : <></>
            }
        </>
    );
}

export default MyTickets;