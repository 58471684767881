import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Box,
  Text,
  Tag,
  Flex,
  IconButton,
  TagLeftIcon
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { eventState, ticketsState, userState, appState } from '../Recoil/atoms';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { MdLocationOn } from "react-icons/md";
import { RxUpdate } from 'react-icons/rx';
import apiHandler from '../Handlers/apiHandler';
import dateHandler from '../Handlers/dateHandler';
import { TicketCreationChart, TicketCheckInChart } from '../Components/TicketStatsChart';
import TicketsStatsTable from '../Components/TicketsStatsTable';
import utilHandlers from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

function StatsPage() {

  const { logout } = useContext(AuthContext);
  
  const { eventId } = useParams();
  const [event, setEvent] = useRecoilState(eventState);
  const [tickets, setTickets] = useRecoilState(ticketsState);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [user, setUser] = useRecoilState(userState);
  const [isLoading, setLoading] = useRecoilState(appState);

  const navigate = useNavigate();

  // Formatear la fecha en la zona horaria local
  const getFormattedDate = (event) => {
    const weekDay = dateHandler.toEsDate(event?.start_date_time, 'weekDay');
    const day = dateHandler.toEsDate(event?.start_date_time, 'day');
    const month = dateHandler.toEsDate(event?.start_date_time, 'month');
    const year = dateHandler.toEsDate(event?.start_date_time, 'year');
    return (
      <span>
        {weekDay} <strong>{day}</strong> {month}. {year}
      </span>
    );
  };

  // Función para generar estadísticas agrupadas por referrer y tipo de ticket
  const generateStats = useCallback((tickets) => {
    const finalStats = tickets.reduce((acc, ticket) => {
      const { referrer, free, checked_in, type } = ticket;

      // Si no hay referrer, asignar "Sin referido"
      const id = referrer ? referrer.id : 'no-referrer';
      const name = referrer ? referrer.name : 'Sin';
      const surnames = referrer ? referrer.surnames : 'referido';

      if (!type) return acc;

      if (!acc[id]) {
        acc[id] = {
          id,
          name,
          surnames,
          types: {}
        };
      }

      if (!acc[id].types[type]) {
        acc[id].types[type] = {
          totalTickets: 0,
          totalFree: 0,
          totalCheckedIn: 0,
          totalFreeAndCheckedIn: 0
        };
      }

      // Sumar estadísticas
      acc[id].types[type].totalTickets += 1;
      if (free) acc[id].types[type].totalFree += 1;
      if (checked_in) acc[id].types[type].totalCheckedIn += 1;
      if (free && checked_in) acc[id].types[type].totalFreeAndCheckedIn += 1;

      return acc;
    }, {});

    // Extraer los tipos de tickets únicos
    const types = [...new Set(tickets?.map(ticket => ticket.type))];
    setTicketTypes(types);

    // Ordenar resultados y asegurar que "Sin referido" esté al final
    return Object.values(finalStats).sort((a, b) => {
      if (a.name === 'Sin' && a.surnames === 'referido') return 1;
      if (b.name === 'Sin' && b.surnames === 'referido') return -1;
      return `${a.name} ${a.surnames}`.localeCompare(`${b.name} ${b.surnames}`);
    });
  }, []); // Mantener la referencia estable

  // Obtener los tickets del evento
  const fetchCurrentTickets = async (eventId) => {
    setLoading(true);
    try {
      const ticketsRes = await apiHandler.getTicketsByEvent(eventId, logout);
      if (!ticketsRes.error) {
        setTickets(ticketsRes);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCurrentTickets(eventId);
  }, [eventId]);

  return (
    <Box padding="10px" w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      <Box display="flex" gap="10px">
        <IconButton
          size="md"
          icon={<ArrowBackIcon />}
          colorScheme="blackScheme"
          variant="outline"
          onClick={() => navigate(`/event/${event?.id}`)}
        />
        <Box display="flex" flexDirection="column" gap="0px" alignItems="start">
          <Text fontSize="lg" fontWeight="600">
            {event?.name} <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="2xs">
              <TagLeftIcon as={MdLocationOn} />
              {event?.venue?.name}
            </Tag>
          </Text>
          <Text fontSize="xs">{getFormattedDate(event)}</Text>
        </Box>
        <IconButton
          size="md"
          icon={<RxUpdate />}
          colorScheme="blackScheme"
          onClick={() => fetchCurrentTickets(event?.id)}
          marginLeft="auto"
        />
      </Box>
      
      <Text fontSize="2xl" fontWeight="600" textAlign="center" textTransform="uppercase" marginTop="20px">STATS</Text>
      
      {user && user.role === 'client' || !user ? (
        <Text fontSize="sm" textAlign="center">
          No tienes los permisos necesarios para ver los Stats
        </Text>
      ) : (
        <>
          {/* Renderizar la tabla de estadísticas */}
          {tickets && (
            <TicketsStatsTable
              tickets={tickets}
              generateStats={generateStats}
              ticketTypes={ticketTypes}
              currentUser={user}
              currentEvent={event}
            />
          )}

          {
            tickets && user && (user.role == 'admin' || user.role == 'masteruser' || utilHandlers.doesUserCan(user?.user_can, 'seecharts', 'venue', event?.venue?.id)) && <>
              {/* Gráfico de creación de tickets */}
              <TicketCreationChart tickets={tickets} />

              {/* Gráfico de check-in de tickets */}
              <TicketCheckInChart tickets={tickets} /> 
            </>
          }
        </>
      )}
    </Box>
  );
}

export default StatsPage;