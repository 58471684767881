import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import LoginForm from '../Components/LoginForm';
import RegisterForm from '../Components/RegisterForm';

import { useRecoilState } from 'recoil';

import { userState, messageState } from '../Recoil/atoms';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginPage = () => {

  const [whatToDo, setWhatToDo] = useState('login');
  const [currentMessage, setCurrentMessage] = useRecoilState(messageState);

  const [user, setUser] = useRecoilState(userState);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Verificar si la URL actual es '/login'
    const isLoginPage = location.pathname === '/login';
    if(user && isLoginPage && whatToDo != 'register') {
      window.location.href = '/';
    }

    if(user && isLoginPage && whatToDo != 'register') {
      window.location.href = '/';
    }
  }, [user])

  return (
    <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100">
      {!messageState && <Text textAlign="center"><span onClick={() => setWhatToDo('login')} style={{fontWeight: "600", cursor: "pointer"}}>Inicia sesión</span> o <span onClick={() => setWhatToDo('register')} style={{fontWeight: "600", cursor: "pointer"}}>regístrate</span> para apuntarte por lista</Text>}
      {
          whatToDo == 'register' ? <>
              <RegisterForm />
              { !messageState && <Text textAlign="center">¿Ya tienes cuenta? <span onClick={() => setWhatToDo('login')} style={{fontWeight: "600", cursor: "pointer"}}>Inicia sesión</span> para apuntarte por lista</Text>}
          </> : <>
              <LoginForm />
              <Text textAlign="center">¿No tienes cuenta? <span onClick={() => setWhatToDo('register')} style={{fontWeight: "600", cursor: "pointer"}}>Regístrate</span> para apuntarte por lista</Text>
          </>
      }
    </Box>
  );
};

export default LoginPage;