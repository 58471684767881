import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiHandler from '../Handlers/apiHandler';
import { useRecoilState } from 'recoil';
import { messageState } from '../Recoil/atoms';

import { AuthContext } from '../context/AuthContext';

const ResetPassword = () => {

  const { logout } = useContext(AuthContext);
  
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [currentMessage, setCurrentMessage] = useRecoilState(messageState);
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentMessage(null);
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }
    try {
      const response = await apiHandler.resetPassword({ token, newPassword }, logout);
      setCurrentMessage(response.data.message);
      setError('');
      setTimeout(() => navigate('/login'), 3000); // Redirigir a login después de 3 segundos
    } catch (err) {
      setError('Error al restablecer la contraseña');
      setCurrentMessage(null);
    }
  };

  return (
    <Box p="10px" display="flex" flexDir="column" alignItems="center" gap="20px">
      <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">Establecer Nueva Contraseña</Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="20px">
          <FormControl id="newPassword">
            <FormLabel>Nueva Contraseña</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>

          <FormControl id="confirmPassword">
            <FormLabel>Confirmar Nueva Contraseña</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>

          {currentMessage && <Text color="green.500">{currentMessage}</Text>}
          {error && <Text color="red.500">{error}</Text>}

          <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme'>
            Restablecer Contraseña
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default ResetPassword;