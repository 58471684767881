import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import { scaleTime, scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { extent, max } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { Box, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, useTheme, SliderMark } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';

import { AuthContext } from '../context/AuthContext';

// Función para agrupar y acumular tickets por bloques de horas de forma progresiva, asegurando el orden cronológico
const groupTicketsByBlock = (tickets, dateKey, blockSizeMinutes = 60) => {
  // Ordenar los tickets por fecha antes de agruparlos
  const sortedTickets = tickets
    ?.filter(ticket => ticket[dateKey])  // Asegurarse de que el ticket tiene una fecha válida
    .sort((a, b) => new Date(a[dateKey]) - new Date(b[dateKey]));  // Ordenar cronológicamente

  const groupedData = sortedTickets.reduce((acc, ticket) => {
    const ticketDate = new Date(ticket[dateKey]);
    const dayKey = ticketDate.toISOString().split('T')[0]; // Agrupar por día

    // Obtener bloques de tiempo definidos por blockSizeMinutes
    const block = Math.floor((ticketDate.getHours() * 60 + ticketDate.getMinutes()) / blockSizeMinutes);
    const blockStart = new Date(ticketDate);
    blockStart.setHours(0, block * blockSizeMinutes, 0, 0); // Ajustar el inicio del bloque de tiempo

    const timeKey = `${dayKey}-${blockStart.getHours()}:${blockStart.getMinutes()}`;

    if (!acc[timeKey]) {
      acc[timeKey] = { date: blockStart, count: 0, total: 0 };
    }

    acc[timeKey].count += 1;

    return acc;
  }, {});

  let totalTickets = 0;

  // Aquí acumulamos el total de tickets de manera progresiva
  const accumulatedData = Object.values(groupedData)?.map((data) => {
    totalTickets += data.count;  // Acumular los tickets progresivamente
    return { ...data, total: totalTickets };
  });

  return accumulatedData;
};

// Componente compartido para mostrar gráficos
const TicketChart = ({ tickets, dateKey, title, blockSizeMinutes, setBlockSizeMinutes }) => {
  
  const { logout } = useContext(AuthContext);

  const [chartData, setChartData] = useState([]);
  const containerRef = useRef();
  const [width, setWidth] = useState(600);
  const height = 300;
  const margin = { top: 20, right: 20, bottom: 20, left: 40 };
  const [hoveredData, setHoveredData] = useState(null); // Estado para el punto seleccionado
  const theme = useTheme(); // Usar el tema de Chakra

  // Agrupar los datos según el tamaño de los bloques de minutos
  useEffect(() => {
    const groupedData = groupTicketsByBlock(tickets, dateKey, blockSizeMinutes);
    setChartData(groupedData);
  }, [tickets, dateKey, blockSizeMinutes]);

  // Escuchar los cambios en el tamaño del contenedor para ajustar el gráfico
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  // Escalas del gráfico
  const xScale = useMemo(() => {
    return scaleTime({
      domain: extent(chartData, (d) => d.date),
      range: [margin.left, width - margin.right],
    });
  }, [chartData, width]);

  const yScale = useMemo(() => {
    return scaleLinear({
      domain: [0, max(chartData, (d) => d.total) || 0],
      range: [height - margin.bottom, margin.top],
    });
  }, [chartData, height]);

  // Función para manejar el hover sobre los puntos
  const handleMouseOver = (event, d) => {
    setHoveredData(d); // Guardar la información del punto
  };

  const handleMouseOut = () => {
    setHoveredData(null); // Limpiar el estado al salir del punto
  };

  return (
    <Box position="relative" padding="20px" w="100%" bg="whiteScheme.100" borderRadius="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px">
        <Text fontSize="md" fontWeight="bold" textAlign="center">
            {title}
        </Text>

        {/* Mostrar la información del punto cuando se haga hover */}
        {hoveredData && (
            <Box
                position="absolute"
                top="0"
                left="50%"
                transform="translateX(-50%)"
                bg="whiteScheme.300"
                padding="5px"
                borderRadius="8px"
                zIndex={10}
            >
                <Text isTruncated fontSize="sm">
                {(() => {
                    const startDate = hoveredData.date;
                    const endDate = new Date(startDate.getTime() + blockSizeMinutes * 60 * 1000);
            
                    const sameDay = startDate.getDate() === endDate.getDate();
                    
                    const startDayFormatted = timeFormat('%d/%m')(startDate); // Formato de la fecha
                    const startHourFormatted = timeFormat('%H:%M')(startDate); // Formato de la hora de inicio
                    const endHourFormatted = timeFormat('%H:%M')(endDate); // Formato de la hora de fin
                    const endDayFormatted = timeFormat('%d/%m')(endDate); // Solo la fecha del día final
            
                    if (sameDay) {
                    return (
                        <>
                        <strong>{startDayFormatted}</strong> {startHourFormatted} - {endHourFormatted}
                        </>
                    );
                    } else {
                    return (
                        <>
                        <strong>{startDayFormatted}</strong> {startHourFormatted} - <strong>{endDayFormatted}</strong> {endHourFormatted}
                        </>
                    );
                    }
                })()}
                </Text>
                <Text fontSize="sm">{`${hoveredData.total} tickets`}</Text>
            </Box>
        )}

        <Box ref={containerRef} >
            <svg width={width} height={height}>
                <Group>
                    <AxisLeft left={margin.left} scale={yScale} />
                    <LinePath
                        data={chartData}
                        x={(d) => xScale(d.date)}
                        y={(d) => yScale(d.total)}
                        stroke={theme.colors.whiteScheme[800]} // Usar el color del tema de Chakra
                        strokeWidth={2}
                        curve={curveMonotoneX}
                    />

                    {chartData?.map((d, i) => (
                        <circle
                        key={`point-${i}`}
                        cx={xScale(d.date)}
                        cy={yScale(d.total)}
                        r={8} // Puntos más grandes
                        fill={hoveredData === d ? theme.colors.whiteScheme[800] : theme.colors.whiteScheme[100]} // Relleno solo al hacer hover
                        stroke={theme.colors.whiteScheme[800]} // Borde del punto
                        strokeWidth={2}
                        onMouseOver={(e) => handleMouseOver(e, d)}
                        onMouseOut={handleMouseOut}
                        />
                    ))}
                </Group>
            </svg>
        </Box>

        <Slider
            aria-label="Agrupación de tiempo"
            min={15} // Mínimo 15 minutos
            max={1440} // Máximo 24 horas (1440 minutos)
            step={15} // Incrementos de 15 minutos
            defaultValue={blockSizeMinutes}
            value={blockSizeMinutes} // Asegurarse de que el valor cambia dinámicamente
            onChange={(val) => setBlockSizeMinutes(val)}
            mb={6}
        >
            <SliderMark
                value={blockSizeMinutes}
                textAlign="center"
                bg={theme.colors.whiteScheme[300]}
                color={theme.colors.whiteScheme[800]}
                mt="5"
                ml="-5"
            >
                {blockSizeMinutes >= 60
                    ? `${blockSizeMinutes / 60}h`  
                    : `${blockSizeMinutes}min`}
            </SliderMark>
            <SliderTrack bg={theme.colors.whiteScheme[400]}>
            <SliderFilledTrack bg={theme.colors.whiteScheme[600]} />
            </SliderTrack>
            <SliderThumb boxSize={6} bg={theme.colors.whiteScheme[800]} />
        </Slider>
    </Box>
  );
};

// Componente específico para la creación de tickets
export const TicketCreationChart = ({ tickets }) => {
  const [blockSizeMinutes, setBlockSizeMinutes] = useState(60); // Inicializar en 1 hora

  return (
    <TicketChart
      tickets={tickets}
      dateKey="createdAt"
      title="Adquisición"
      blockSizeMinutes={blockSizeMinutes}
      setBlockSizeMinutes={setBlockSizeMinutes}
    />
  );
};

// Componente específico para el check-in de tickets
export const TicketCheckInChart = ({ tickets }) => {
  const [blockSizeMinutes, setBlockSizeMinutes] = useState(60); // Inicializar en 1 hora

  return (
    <TicketChart
      tickets={tickets}
      dateKey="checked_in_when"
      title="Check In"
      blockSizeMinutes={blockSizeMinutes}
      setBlockSizeMinutes={setBlockSizeMinutes}
    />
  );
};