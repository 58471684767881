import React from 'react';
import { Box, Button } from '@chakra-ui/react';

const EventFilters = ({ whenEvents, setWhenEvents }) => {
    return (
        <Box gap="10px" display="flex" justifyContent="center" padding="10px" borderRadius="full" borderWidth="2px" borderColor="whiteScheme.400" bg="whiteScheme.400">
            {['upcoming', 'past'].map((filter) => (
                <Button
                    key={filter}
                    variant={whenEvents === filter ? 'solid' : 'outline'}
                    borderRadius="full"
                    colorScheme="blackScheme"
                    onClick={() => setWhenEvents(filter)}
                    size="sm"
                >
                    {filter === 'upcoming' ? 'Próximos' : 'Pasados'}
                </Button>
            ))}
        </Box>
    );
};

export default EventFilters;